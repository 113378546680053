#footer #footer-header {
    font-size: 14px;
    font-weight: 600;
    color: #666;
    margin: 30px 0;
    border-bottom: 1px solid #ccc;
}

    #footer #footer-header span {
        display: inline-block;
        background-color: #fff;
        padding-right: 40px;
        transform: translateY(5px);
        border-bottom: 0px solid #ccc;
        font-family: 'utmavo';
    }

.footer-csdkbm {
    padding: 0;
    color: #00B6F3 !important;
    transition: all .3s ease-in-out;
}

.footer-title__qrcode {
    text-transform: uppercase;
    text-align: center;
}

.footer-csdkbm.footer-csdkbm__qr {
    text-align: center
}

.footer-csdkbm:hover {
    color: rgb(253, 80, 86) !important;
}

#footer #footer-ttcs-address {
    font-family: system-ui;
}

    #footer #footer-ttcs-address #footer-ttcs-address-logoimg {
        font-size: 16px;
        text-align: left
    }

        #footer #footer-ttcs-address #footer-ttcs-address-logoimg img {
            width: 150px;
        }

.footer-ttcs-address-info {
    font-size: 14px;
    text-align: left
}

#footer #ttcs-qd {
    font-family: system-ui;
}

#footer #ttcs-dkctv {
    text-align: left;
    font-family: system-ui
}

.copy-right {
    margin-bottom: 10px;
    color: #2d4271;
    font-weight: 500;
    font-size: 15px;
}

@media only screen and (max-width:767.98px) {
    .footer-csdkbm {
        text-align: left;
    }

    .footer-title__qrcode {
        text-align: left;
    }

    .footer-csdkbm.footer-csdkbm__qr {
        text-align: left;
    }

    #footer #ttcs-qd,
    #footer #footer-ttcs-address {
        margin-bottom: 1rem;
    }
}
