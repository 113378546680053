:root {
    --color-primary: #EF5A39;
}

/* PUBLIC */
.hide {
    display: none;
}

/* PRIVATE */
.order {
    padding-top: 20px;
}

.order-title {
    display: flex;
    margin-bottom: 1rem;
    background-color: #ed4621;
    align-items: center;
    justify-content: center;
}

.order-title__heading {
    font-weight: 700;
    color: #fff;
    font-size: 18px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    display: none;
}
/* -------------------------------- 

xnugget info 

-------------------------------- */
.cd-nugget-info {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 50px;
    line-height: 50px;
    top: 0;
    left: 0;
}

    .cd-nugget-info a {
        position: relative;
        font-size: 14px;
        color: #96c03d;
        -webkit-transition: all 0.2s;
        -moz-transition: all 0.2s;
        transition: all 0.2s;
    }

.no-touch .cd-nugget-info a:hover {
    opacity: .8;
}

.cd-nugget-info span {
    vertical-align: middle;
    display: inline-block;
}

    .cd-nugget-info span svg {
        display: block;
    }

.cd-nugget-info .cd-nugget-info-arrow {
    fill: #96c03d;
}

/* -------------------------------- 

Basic Style

-------------------------------- */
.cd-breadcrumb, .cd-multi-steps {
    /* width: 90%; */
    /* max-width: 768px; */
    padding: 0.5em 1em;
    /* margin: 1em auto; */
    /* background-color: #edeff0; */
    border-radius: .25em;
}

    .cd-breadcrumb::after, .cd-multi-steps::after {
        clear: both;
        content: "";
        display: table;
    }

    .cd-breadcrumb li, .cd-multi-steps li {
        display: inline-block;
        float: left;
        margin: 0.5em 0;
    }

        .cd-breadcrumb li::after, .cd-multi-steps li::after {
            /* this is the separator between items */
            display: inline-block;
            content: '\00bb';
            margin: 0 .6em;
            color: #959fa5;
        }

        .cd-breadcrumb li:last-of-type::after, .cd-multi-steps li:last-of-type::after {
            /* hide separator after the last item */
            display: none;
        }

        .cd-breadcrumb li > *, .cd-multi-steps li > * {
            /* single step */
            display: inline-block;
            font-size: 16px;
            /*text-transform:uppercase;*/
            color: #fff;
            font-weight: 600;
            transition: all 0.3s ease-in-out;
        }

        .cd-breadcrumb li:first-child a {
            text-decoration: underline;
        }

        .cd-breadcrumb li.current > *, .cd-multi-steps li.current > * {
            /* selected step */
            color: #fff;
        }

.no-touch .cd-breadcrumb a:hover, .no-touch .cd-multi-steps a:hover {
    /* steps already visited */
    color: #fff;
}

.cd-breadcrumb.custom-separator li::after, .cd-multi-steps.custom-separator li::after {
    /* replace the default separator with a custom icon */
    content: '';
    height: 10px;
    width: 10px;
    background: url(../../../wwwroot/images/right-arrow.png) no-repeat center center;
    vertical-align: middle;
}

.cd-breadcrumb.custom-icons li > *::before, .cd-multi-steps.custom-icons li > *::before {
    /* add a custom icon before each item */
    content: '';
    display: inline-block;
    height: 20px;
    width: 20px;
    margin-right: .4em;
    margin-top: -2px;
    background: url(https://codyhouse.co/demo/breadcrumbs-multi-steps-indicator/img/cd-custom-icons-01.svg) no-repeat 0 0;
    vertical-align: middle;
}

.cd-breadcrumb.custom-icons li:not(.current):nth-of-type(2) > *::before, .cd-multi-steps.custom-icons li:not(.current):nth-of-type(2) > *::before {
    /* change custom icon using image sprites */
    background-position: -20px 0;
}

.cd-breadcrumb.custom-icons li:not(.current):nth-of-type(3) > *::before, .cd-multi-steps.custom-icons li:not(.current):nth-of-type(3) > *::before {
    background-position: -40px 0;
}

.cd-breadcrumb.custom-icons li:not(.current):nth-of-type(4) > *::before, .cd-multi-steps.custom-icons li:not(.current):nth-of-type(4) > *::before {
    background-position: -60px 0;
}

.cd-breadcrumb.custom-icons li.current:first-of-type > *::before, .cd-multi-steps.custom-icons li.current:first-of-type > *::before {
    /* change custom icon for the current item */
    background-position: 0 -20px;
}

.cd-breadcrumb.custom-icons li.current:nth-of-type(2) > *::before, .cd-multi-steps.custom-icons li.current:nth-of-type(2) > *::before {
    background-position: -20px -20px;
}

.cd-breadcrumb.custom-icons li.current:nth-of-type(3) > *::before, .cd-multi-steps.custom-icons li.current:nth-of-type(3) > *::before {
    background-position: -40px -20px;
}

.cd-breadcrumb.custom-icons li.current:nth-of-type(4) > *::before, .cd-multi-steps.custom-icons li.current:nth-of-type(4) > *::before {
    background-position: -60px -20px;
}

@media only screen and (min-width: 768px) {
    .cd-breadcrumb, .cd-multi-steps {
        padding: 0;
        margin-bottom: 0;
    }
}

/* -------------------------------- 

Custom icons hover effects - breadcrumb and multi-steps

-------------------------------- */
@media only screen and (min-width: 768px) {
    .no-touch .cd-breadcrumb.triangle.custom-icons li:first-of-type a:hover::before, .cd-breadcrumb.triangle.custom-icons li.current:first-of-type em::before, .no-touch .cd-multi-steps.text-center.custom-icons li:first-of-type a:hover::before, .cd-multi-steps.text-center.custom-icons li.current:first-of-type em::before {
        /* change custom icon using image sprites - hover effect or current item */
        background-position: 0 -40px;
    }

    .no-touch .cd-breadcrumb.triangle.custom-icons li:nth-of-type(2) a:hover::before, .cd-breadcrumb.triangle.custom-icons li.current:nth-of-type(2) em::before, .no-touch .cd-multi-steps.text-center.custom-icons li:nth-of-type(2) a:hover::before, .cd-multi-steps.text-center.custom-icons li.current:nth-of-type(2) em::before {
        background-position: -20px -40px;
    }

    .no-touch .cd-breadcrumb.triangle.custom-icons li:nth-of-type(3) a:hover::before, .cd-breadcrumb.triangle.custom-icons li.current:nth-of-type(3) em::before, .no-touch .cd-multi-steps.text-center.custom-icons li:nth-of-type(3) a:hover::before, .cd-multi-steps.text-center.custom-icons li.current:nth-of-type(3) em::before {
        background-position: -40px -40px;
    }

    .no-touch .cd-breadcrumb.triangle.custom-icons li:nth-of-type(4) a:hover::before, .cd-breadcrumb.triangle.custom-icons li.current:nth-of-type(4) em::before, .no-touch .cd-multi-steps.text-center.custom-icons li:nth-of-type(4) a:hover::before, .cd-multi-steps.text-center.custom-icons li.current:nth-of-type(4) em::before {
        background-position: -60px -40px;
    }
}
/* -------------------------------- 

Multi steps indicator 

-------------------------------- */

@media only screen and (max-width: 768px) {
    .order-title__heading {
        font-size: 16px;
        display: block;
    }

    .cd-breadcrumb {
        display: none
    }
}

@media only screen and (max-width: 325px) {
    .order-title__heading {
        font-size: 13px;
    }
}

.photos-grid-container .sub {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1rem;
}

.book-info {
    margin-bottom: 2rem !important;
    margin: 0 auto;
    position: relative;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: linear-gradient(64.4deg,#D81920 21.33%,#fe2214 67.61%);
    border-radius: .7rem;
}

    .book-info center {
        padding: .7rem;
    }

        .book-info center strong {
            color: #fff;
            font-family: 'KoHo', sans-serif;
            font-size: 18px;
        }

    .book-info .form-info {
        background: #fff;
    }

    .book-info .group-info {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

        .book-info .group-info.group-info__product {
            justify-content: space-between;
        }

            .book-info .group-info.group-info__product .input-number {
                width: unset;
            }

.tour-detail .book-info .group-info .info-item {
    margin: .7rem;
}

.book-info .group-info .info-item .control-label {
    margin-bottom: .7rem !important;
    font-family: "Koho",sans-serif !important;
}

.book-info .group-info .info-item #BtnSearchRoom {
    color: #000000;
    width: fit-content;
    height: 40px;
    font-size: 16px;
    background: linear-gradient(26.73deg, #F9A51A 13.7%, #FBB612 29.8%, #FFDD00 66.81%);
    font-family: "KoHo",sans-serif;
    border-radius: 5px;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    padding: 6px 14px;
    min-width: 120px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-weight: 500;
    line-height: 1.75;
    text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
    .tour-detail .book-info .group-info .info-item.info-select {
        width: 100%;
    }
}

@media only screen and (max-width: 375px) {
    .tour-detail .book-info .group-info .info-item.info-select {
        flex-direction: column;
    }
}

.tour-detail .book-info .group-info .info-item .info-item__block {
    width: calc(100% / 3 - 12px);
}

    .tour-detail .book-info .group-info .info-item .info-item__block:not(:first-child) {
        margin-left: 12px;
    }

@media only screen and (max-width: 375px) {
    .tour-detail .book-info .group-info .info-item .info-item__block {
        width: 100%;
    }

        .tour-detail .book-info .group-info .info-item .info-item__block:not(:first-child) {
            margin-left: 0;
        }
}

.t-check-in {
    border-radius: .7rem;
}

.info-footer {
    background: #fff;
    padding: .7rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 3px solid #fe2214;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

@media only screen and (max-width: 480px) {
    .info-footer {
        flex-direction: column;
    }
}

.info-footer .info-price strong {
    text-transform: uppercase;
    font-size: 16px;
    color: #2d4271;
}

.info-footer .info-price {
    text-transform: uppercase;
    font-size: 22px;
    color: #48BB78;
    font-weight: 700;
}

.info-footer .info-price__total strong {
    text-transform: uppercase;
    font-size: 16px;
    color: #2d4271;
}

.info-footer .info-price__total {
    text-transform: uppercase;
    font-size: 22px;
    color: #fd5056;
    font-weight: 700;
}

@media only screen and (max-width: 768px) {
    .tour-detail .info-footer .info-price__total,
    .tour-detail .info-footer .info-price {
        font-size: 18px;
    }
}

.tour-detail .input-number {
    width: 80px;
    padding: 0 12px;
    vertical-align: top;
    text-align: center;
    outline: none;
}

@media only screen and (max-width: 1200px) {
    .tour-detail .input-number {
        width: 40px;
    }
}

.input-number,
.input-number-decrement,
.input-number-increment {
    border: 1px solid #ccc;
    height: 40px;
    user-select: none;
}

.input-number-decrement,
.input-number-increment {
    display: inline-block;
    width: 30px;
    line-height: 38px;
    background: #f1f1f1;
    color: #444;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}

    .input-number-decrement:active,
    .input-number-increment:active {
        background: #ddd;
    }

.input-number-decrement {
    border-right: none;
    border-radius: 4px 0 0 4px;
}

.input-number-increment {
    border-left: none;
    border-radius: 0 4px 4px 0;
}

.tour-order {
    padding: 10px;
    border: 1px solid #d5d5d5;
    border-radius: .3rem;
}

    .tour-order strong {
        font-weight: 700;
        margin-bottom: 20px;
        display: block;
    }

.item-order {
    text-align: center;
}

.tour-order .item-order img {
    border-radius: .7rem;
}

.btn-detail {
    min-width: 12rem;
}

.input-cta__date {
    width: 100%;
    height: 3.8rem;
}

.panel-info > .panel-heading {
    background: rgb(229, 248, 254);
    color: #00B6F3;
}

.item-order .similar-item-box img {
    width: 100%;
    height: 100%;
}

.info-label {
    width: 100%;
    font-size: 14px;
    text-align: left;
}

@media(max-width:1119.98px) {
    .input-number {
        width: 55px;
        padding: 0 5px;
    }


    .input-number-decrement, .input-number-increment {
        width: 25px;
    }
}

@media (max-width:991.98px) {
    .tz-gallery__checkout .photos-grid-container {
        grid-template-columns: 1fr;
    }

    .tz-gallery__checkout .sub {
        display: none;
    }
}

@media(max-width:767.98px) {
    .photos-grid-container .sub {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }

    .book-info .group-info {
        flex-direction: column;
        align-items: start;
    }

        .book-info .group-info .info-item:last-child label {
            display: none;
        }

    .photos-grid-container .main-photo {
        height: 267px;
    }

    .c-navigation-breadcrumbs {
        margin-top: 3rem;
    }
}

@media(max-width:375.98px) {
    .info-footer {
        flex-direction: column;
    }
}

.order-info__content {
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    border-color: #bce8f1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.order-info__content-head {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    background: #e5f8fe;
    color: #00b6f3;
}

    .order-info__content-head span {
        text-transform: uppercase;
        font-weight: 600;
    }

.order-info__content-head--btn {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    margin: 10px;
    padding: 6px 10px;
    border-radius: 4px;
    color: #fff;
    background-color: #00b6f3;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
}

.order-info__content-main.show {
    height: 100%;
    padding: 12px 16px;
    transition: all 0.6s ease;
    animation: show 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}

.order-info__content-main.hide {
    height: 0;
    padding: 0;
    transition: all 0.6s ease;
    animation: hide 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}
