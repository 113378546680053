.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    transform-origin: center;
    transition: all .5s ease-in-out;
}

    .modal-container.enable {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
    }

.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
}

.modal-lucky {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    min-width: 60rem;
}

.title-sale {
    background: -webkit-linear-gradient(rgb(216, 25, 32), rgb(254, 34, 20));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    font-size: 1.6rem;
    margin-bottom: 2rem;
}

@media only screen and (max-width: 767.98px) {
    .modal-lucky.modal-lucky__form {
        width: 95%;
        min-width: unset !important;
    }
}

@media only screen and (max-width: 280.98px) {
    .lucky-group {
        flex-direction: column;
        align-items: flex-start;
    }

    .modal-lucky.modal-lucky__form .lucky-heading h4 {
        font-size: 1.3rem;
        margin-bottom: 0;
    }

    .modal-lucky.modal-lucky__form .title-sale {
        font-size: 1.2rem;
    }
}

@media only screen and (max-width: 575.98px) {
    .modal-lucky.modal-lucky__sale {
        min-width: 50rem;
    }
}

@media only screen and (max-width: 320.98px) {
    .modal-lucky.modal-lucky__sale {
        min-width: 43rem;
    }
}

@media only screen and (max-width: 280.98px) {
    .modal-lucky.modal-lucky__sale {
        min-width: 40rem;
    }
}
/*@media only screen and (max-width: 375.98px) {
    .modal-lucky.modal-lucky__sale {
        min-width: 43rem;
    }
}*/

.modal-lucky.modal-lucky__form {
    min-width: 60rem;
}

.modal-lucky img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media only screen and (max-width:1199.98px) {
    .modal-inner {
        min-width: 95%;
        height: 55%;
    }

    .modal-inner {
        height: 60%;
    }

        .modal-inner.modal-pay {
            height: 75%;
        }
}
