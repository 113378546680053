.btn.buttonDetail {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .1rem;
    text-transform: uppercase;
    border-color: #214252;
    background-color: #214252;
    color: #fff;
    border: 1px solid;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

    .btn.buttonDetail:hover {
        background-color: transparent;
        color: #214252;
        border-color: #214252;
        text-align: center;
    }

.btn.btnBuidTour {
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 700;
    border: 1px solid #EF5A39;
    background-color: #fff;
    color: #EF5A39;
    box-shadow: 3px 3px 0px #EF5A39;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    margin-left: 1rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

    .btn.btnBuidTour:hover {
        background-color: #EF5A39;
        color: #fff;
        border-color: #EF5A39;
        text-align: center;
        text-decoration: none;
        opacity: 0.8;
        box-shadow: none;
        transform: translateY(0.1rem);
    }

.BTsearchbk {
    color: #000000;
    background: linear-gradient(26.73deg, #F9A51A 13.7%, #FBB612 29.8%, #FFDD00 66.81%);
    width: 30%;
    font-weight: 700;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-weight: 700;
}

.btn-pay {
    color: #fff;
    background-color: #fd5056;
    border-color: #fd5056;
}

.btn-payment {
    background: linear-gradient(64.4deg,#fd5056 21.33%,#fe2214 67.61%);
    color: #fff;
    font-weight: 700;
    width: 100%;
}

    .btn-payment:hover {
        color: #fff;
    }

.btn-overview {
    color: #fff;
    background-color: #198754;
    border-color: #198754;
    margin-right: 1rem;
}

    .btn-pay:focus,
    .btn-overview:focus,
    .btn-overview:hover,
    .btn-pay:hover,
    .btn-getcode:hover,
    .btn-primary-baydidau {
        color: #fff;
    }

.btn-getcode {
    background-color: #198754;
    border: #198754;
    color: #fff;
    font-weight: 400;
}

.btn-primary-baydidau {
    background: #EF5A39;
    border-color: #EF5A39;
    color: #fff;
}

.discount-footer #btnDiscountCode {
    padding: 0.5rem;
    border: 1px solid #e7e7e7;
    border-radius: 0.3rem;
    margin-right: 0.7rem;
}

.btn-book {
    /*width: 50%;*/
    min-width: 12rem;
}

.btn-confirm {
    background-color: rgb(48, 133, 214);
    color: #fff;
}

    .btn-confirm:hover {
        color: #fff;
        opacity: 0.9;
    }

@media only screen and (max-width:575.98px) {
    .buttonDetail {
        font-size: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .btn.btnBuidTour {
        font-size: 10px;
        padding-top: 7px;
        padding-bottom: 7px;
        padding-left: 10px;
        padding-right: 10px;
        letter-spacing: unset;
    }
}

@media only screen and (max-width:320.98px) {
    .btn.buttonDetail {
        font-size: 1rem;
    }
}
