/* PUBLIC */
.hide {
    display: none;
}

/* PRIVATE */
.register .form-group {
    margin-bottom: 24px;
    align-items: center;
}

.register .form-control {
    height: unset;
    padding: 8px 14px;
    font-size: 14px;
    border-radius: 4px;
    box-shadow: rgba(50, 50, 93, 0.25) 1px 2px 2px -1px;
}

.register button {
    padding: 8px 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.register .form-input {
    position: relative;
}

.register .form__error {
    position: absolute;
    right: 15px;
    color: #fd5056;
    margin-top: 4px;
    font-size: 12px;
}
