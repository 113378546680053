.modal-fullscreen__container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: rgb(57, 190, 185);
    overflow-y: auto;
    z-index: 9999;
    opacity: 1;
    animation-duration: 0.6s;
}

.modal-fullscreen__content {
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow-x: hidden;
}

.modal-fullscreen__close {
    cursor: pointer;
    display: flex;
    font-size: 1.75em;
    height: 25px;
    line-height: 50px;
    margin-left: auto;
    overflow: hidden;
    padding: 0px;
    position: relative;
    width: 25px;
    align-items: center;
    justify-content: center;
}

.modal-fullscreen__heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transform: translateY(1rem);
    padding-right: 1rem;
}

.modal-fullscreen__body {
    padding: 1rem;
    margin-top: -3rem;
}

.filter-heading {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}

    .filter-heading .filter-title {
        font-size: 20px;
        font-weight: 600;
    }

.filter-inner .filter-block {
    border-bottom: 1px solid #f5f5f5;
}
