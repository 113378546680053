
.span-line{
    margin:0 5px;
}
.hide {
    display: none;
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

.header {
    width: 100%;
}

.header-section {
    padding: 0.3rem 0px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 7px 2px;
    background-color: rgb(239, 90, 57) !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
}

.header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.phone-ctv {
    font-size: 1.6rem;
    color: #fff;
    text-decoration: none !important;
    font-weight: 600;
    transition: all .3s ease-in-out;
}

    .phone-ctv:hover {
        color: #fff !important;
    }

.header-top .header-logo {
    height: 40px;
    width: 40px;
    background-color: rgb(255, 255, 255);
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.75) 0px 0px 3px 0px inset;
    padding: 0;
}

    .header-top .header-logo img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

.header-center {
    display: flex;
    /*flex-direction: column;*/
    align-items: center;
    color: #fff;
    margin-left: 2rem;
}

    .header-center .name-ctv {
        font-size: 1.5rem;
        color: #fff;
    }

.lucky-whell {
    cursor: pointer;
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.header #header-logo #header-logo-img {
    height: 2rem;
    width: 2rem;
}

.header-top .header-login .btn-login {
    color: rgb(255, 255, 255);
    background-color: rgb(255, 189, 11);
    border-color: rgb(226, 151, 42);
}

.header-top .menu {
    width: 22px;
    height: 16px;
    cursor: pointer;
    display: none;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

    .header-top .menu span {
        width: 100%;
        height: 2px;
        border-radius: 99px;
        background: rgb(255, 255, 255);
        transition: all 0.3s ease 0s;
        transform-origin: left center;
    }

.header-login {
    display: flex;
    align-items: center;
    transition: all 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
}

    .header-login .input-box {
        position: relative;
        height: 33px;
        max-width: 40px;
        width: 100%;
        margin: 0px 10px;
        border-radius: 6px;
        background-color: rgb(255, 189, 11);
        transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;
    }

        .header-login .input-box.open {
            max-width: 235px;
        }

        .header-login .input-box.disable {
            display: none;
        }

    .header-login input {
        position: relative;
        outline: none;
        border: none;
        height: 100%;
        width: 100%;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 400;
        color: rgb(255, 255, 255);
        background-color: rgb(255, 189, 11);
    }

        .header-login input::placeholder {
            color: rgb(255, 255, 255);
        }

    .header-login .input-box.open {
        padding: 0px 28px 0px 50px;
    }

    .header-login .icon {
        position: absolute;
        height: 100%;
        top: 0px;
        left: 0px;
        width: 40px;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        background-color: rgb(255, 189, 11);
        cursor: pointer;
    }

    .header-login .search-icon, .header-login .close-icon {
        position: absolute;
        top: 50%;
        font-size: 23px;
        transition: all 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55) 0s;
    }

    .header-login .search-icon {
        color: rgb(255, 255, 255);
        transform: translateY(-50%) rotate(90deg);
    }

    .header-login .input-box.open .search-icon {
        transform: translateY(-50%) rotate(0deg);
    }

    .header-login .close-icon {
        right: -10px;
        color: rgb(255, 255, 255);
        border-radius: 6px;
        padding: 5px 14px;
        cursor: pointer;
        opacity: 0;
        pointer-events: none;
        transform: translateY(-50%);
    }

    .header-login .input-box.open .close-icon {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(-50%) rotate(180deg);
    }

.lucky-whell img {
    width: 50px;
    height: 50px;
}

.header-top ul {
    display: flex;
    align-items: center;
    overflow-y: hidden;
    transition: all 1s cubic-bezier(0.68, -0.55, 0.27, 10.55) 0s;
    margin-bottom: 0px;
}

    .header-top ul li a {
        color: rgb(255, 255, 255);
        text-decoration: none;
        position: relative;
    }

        .header-top ul li a::after {
            content: "";
            width: 0%;
            height: 1.7px;
            border-radius: 99px;
            background: var(--main-clr);
            position: absolute;
            bottom: 0px;
            left: 0px;
            transition: all 0.3s ease 0s;
        }

.header-top .menu {
    width: 22px;
    height: 16px;
    cursor: pointer;
    display: none;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

    .header-top .menu span {
        width: 100%;
        height: 2px;
        border-radius: 99px;
        background: rgb(255, 255, 255);
        transition: all 0.3s ease 0s;
        transform-origin: left center;
    }

    .header-top .menu.active span:nth-child(1) {
        transform: rotate(40deg);
    }

    .header-top .menu span:nth-child(3) {
        transform-origin: left center;
    }

    .header-top .menu.active span:nth-child(3) {
        transform: rotate(-40deg);
    }

    .header-top .menu.active span:nth-child(2) {
        transform: scale(0);
    }

.lucky-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background: linear-gradient(64.4deg, rgb(216, 25, 32) 21.33%, rgb(254, 34, 20) 67.61%);
    color: rgb(255, 255, 255);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.form-horizontal__lucky {
    padding: 1rem;
    background: rgb(255, 255, 255);
    border-bottom: 3px solid rgb(254, 34, 20);
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.lucky-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.lucky-label {
    width: 15rem;
    font-weight: 700
}

.btn-lucky__form {
    margin-left: auto;
    display: block !important;
    background-color: rgb(1, 135, 160) !important;
    border-color: rgb(1, 135, 160) !important;
}

@media only screen and (max-width:991.98px) {
    .header-top .header-login .btn-login span {
        display: none;
    }

    .header-center {
        margin-left: 6rem;
    }
}

@media only screen and (max-width: 767.98px) {
    .header-top {
        padding: 0px 1rem;
    }

    .header-login {
        padding: 1rem 0px;
    }

    .header-center {
        margin-left: -1rem;
        flex-direction:column;
    }
    .span-line{
        display:none;
    }
    .header-top .menu {
        display: flex;
    }

    .header-top ul {
        --height: 0px;
        flex-direction: column;
        position: absolute;
        width: 100%;
        left: 0px;
        top: 46px;
        height: var(--height);
        transition: all 1s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
        background: rgb(239, 90, 57) !important;
    }

        .header-top ul.active {
            --height: calc( (((var(--padding) * 2) + (var(--font-size) * 1.5))) * var(--childenNumber) );
            transition: all 1s ease 0s;
            box-shadow: rgba(0, 0, 0, 0.5) 0px 9px 6px 0px;
        }

        .header-top ul li {
            width: 100%;
            text-align: right;
        }

    .header-login {
        padding: 0.7rem 1rem;
        justify-content: center;
    }

        .header-login a span {
            font-size: 14px;
        }

    .header-top ul li a {
        text-transform: capitalize;
        text-align: left;
    }
}

@media only screen and (max-width: 320.98px) {
    .header-center .name-ctv {
        font-size: 1.2rem;
    }

    .phone-ctv {
        font-size: 1.4rem;
    }
}
