/* PUBLIC */
.hide {
    display: none;
}

/* PRIVATE */
.popup {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.5);
    z-index: 9999;
    display: none;
}

    .popup.show {
        display: block;
    }

    .popup .popup-close {
        width: 30px;
        height: 30px;
        background-color: #fff;
        color: #333;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all .3s ease-in-out;
        position: absolute;
        z-index: 10;
        top: 1rem;
        right: 1rem;
    }

    .popup .popup-inner {
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 1rem;
        box-shadow: 0 0 3px rgba(0,0,0,0.6);
        height: 90%;
        min-width: 90%;
    }

    .popup .popup-body {
        position: inherit;
        width: 100%;
        height: 100%;
        color: #ffffff;
        background-color: #1e1e22;
        border-radius: 1rem;
        overflow: hidden;
    }

.popup-body .swiper {
    height: 100%;
}

.swiper .swiper-slide .swiper-slide__image {
    width: 100%;
    height: 100%;
}

    .swiper .swiper-slide .swiper-slide__image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

.swiper .swiper-slide .swiper-slide__title {
    position: absolute;
    bottom: 20%;
    left: 50px;
}

.popup-body .swiper-slide .swiper-slide__title {
    text-transform: uppercase;
    font-family: "Times New Roman";
    font-weight: 900;
    font-size: 4rem;
}


.swiper-button-prev, .swiper-button-next {
    top: unset;
    bottom: 10%;
    left: 50px;
    padding: 24px 28px;
    border: 1px solid #fff;
    user-select: none;
    cursor: pointer;
}

    .swiper-button-prev::after, .swiper-button-next::after {
        font-size: 14px;
        font-weight: bold;
    }

.swiper-button-prev {
}

.swiper-button-next {
    right: unset;
    left: 110px;
}

.swiper .swiper-slide.swiper-slide-prev .swiper-slide__image,
.swiper .swiper-slide.swiper-slide-next .swiper-slide__image {
    transform: scale(0.5);
    transition: all .5s ease;
}

.swiper .swiper-slide.swiper-slide-active .swiper-slide__image {
    transform: scale(1);
    transition: all 2s ease;
}
