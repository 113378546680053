/* PUBLIC */
.hide {
    display: none;
}

/* PRIVATE */
.checkout .frame-form__foot {
    padding: 3rem;
    background: #fff;
    border-bottom: 3px solid #fe2214;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

@media only screen and (max-width: 375px) {
    .checkout .frame-form__foot {
        padding: 3.5rem 2rem;
    }
}

.checkout-bill-foot .checkout-bill-info__item:last-child {
    padding-bottom: 8px;
    border-top: 1px solid #e7e7e7;
}

.frame-form__head {
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 1rem 0;
}

.frame-form {
    background: linear-gradient(64.4deg,#D81920 21.33%,#fe2214 67.61%);
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
}

.checkout-form {
    margin-bottom: 24px;
}

.checkout-form-group {
    position: relative;
}

.checkout-form-group__input {
    width: 100%;
    padding: 4px 12px;
    border: none;
    z-index: 3;
    color: #333;
    transition: 0.3s;
    background: transparent;
    border-bottom: 1px solid #333;
}

.checkout-form-group__label {
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 4px;
    pointer-events: none;
    transition: 0.3s;
}

.checkout-form-group__input:focus + .checkout-form-group__label,
.checkout-form-group__input:valid + .checkout-form-group__label {
    color: #ef5a39;
    transform: translateY(-20px);
}

.checkout-form-group__input:focus, .checkout-form-group__input:valid {
    outline: none;
    border-color: #d81920;
    transition: all 0.2s ease-in;
}

.checkout-form-group__error {
    position: absolute;
    bottom: -20px;
    right: 0;
    font-size: 10px;
    color: #fd5056;
}

.checkout-form-group__title {
    font-size: 12px;
    font-weight: bold;
    font-family: "Koho", sans-serif;
}

    .checkout-form-group__title:not(:last-child) {
        margin-bottom: 4px;
    }

    .checkout-form-group__title:last-child {
        margin-top: 6px;
    }

.checkout-form-group__textarea {
    display: block;
    width: 100%;
    height: 100px;
    outline: none;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid #333;
}

.checkout-form-group {
    margin-top: 34px;
}

.checkout-form .group-phone, .checkout-form .group-email {
    width: 50%;
}

.modal-lucky.modal-lucky__payment {
    min-width: 32rem;
    z-index: 100;
}

.modal-payment {
    position: relative;
    box-sizing: border-box;
    padding: 0 0 1.25em;
    border: none;
    border-radius: 5px;
    background: #fff;
    color: #545454;
    font-family: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
}

    .modal-payment .title {
        font-size: 2.5rem;
        font-weight: 700;
    }

    .modal-payment svg {
        width: 70px;
        display: block;
        margin: 20px auto 0;
    }

    .modal-payment .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 0;
    }

        .modal-payment .path.circle {
            -webkit-animation: dash 0.9s ease-in-out;
            animation: dash 0.9s ease-in-out;
            animation-delay: .4s;
            -webkit-animation-delay: .4s;
        }

        .modal-payment .path.line {
            stroke-dashoffset: 1000;
            -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
            animation: dash 0.9s 0.35s ease-in-out forwards;
        }

        .modal-payment .path.check {
            stroke-dashoffset: -100;
            -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
            animation: dash-check 0.9s 0.35s ease-in-out forwards;
            animation-delay: .4s;
            -webkit-animation-delay: .4s;
        }

@-webkit-keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@-webkit-keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }

    100% {
        stroke-dashoffset: 900;
    }
}

@keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }

    100% {
        stroke-dashoffset: 900;
    }
}

.pay-block {
    padding: 16px 15px 0 15px;
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.12);
}

.pay-method {
    margin-top: 30px;
    padding-bottom: 25px;
}

.info-customer .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    margin-bottom: 10px;
    padding-bottom: 15px;
    color: #303644;
    border-bottom: 1px solid #e7e7e7;
}

.item-new {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
}

    .item-new .item-b {
        padding: 15px 5px;
        display: flex;
        align-items: center;
        flex: 0 0 50%;
        max-width: 48%;
        transition: all .5s ease-in-out;
        background-color: #f5f7f9;
    }

        .item-new .item-b:hover {
            cursor: pointer;
            box-shadow: 0 4px 8px rgba(33,38,44,.16);
        }

            .item-new .item-b:hover p {
                color: #dc3545;
            }

        .item-new .item-b img {
            width: 50px;
        }

        .item-new .item-b p {
            padding-left: 15px;
            font-size: 18px;
            margin-bottom: 0;
            color: #303644;
            font-weight: 500;
            transition: all .5s ease-in-out;
        }

.vnpay--red {
    color: #ed1c24;
}

.vnpay--blue {
    color: #005baa;
}

@media only screen and (min-width: 1200px) {
    .checkout-form .group-email {
        margin-left: 48px;
    }
}

.checkout-form .group-request {
    margin-top: 34px;
}

.checkout-btn {
    gap: 6px;
}

.checkout-btn__cash,
.checkout-btn__online {
    min-width: 200px;
    font-size: 12px;
    color: #fff;
    background: #333;
    border-radius: 5px;
    padding: 8px 14px;
    font-weight: 600;
    margin-top: 2rem;
    box-sizing: border-box;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

@media only screen and (max-width: 375px) {
    .checkout-btn__cash,
    .checkout-btn__online {
        margin-top: 1rem;
    }
}

.checkout-btn__cash.disable {
    pointer-events: none;
}

.checkout-btn__cash:hover,
.checkout-btn__online:hover {
    opacity: .8;
}

@media only screen and (max-width: 767.98px) {
    .checkout-btn__cash,
    .checkout-btn__online {
        width: 100%;
    }

    .item-new {
        flex-direction: column;
        align-content: flex-start;
    }

        .item-new .item-b {
            min-width: 100%;
        }

    .info-customer .title {
        font-size: 1.5rem;
    }

    .item-new .item-b p {
        font-size: 1.5rem;
    }
}

.checkout-btn__online {
    background: #0187a0;
    border: none;
}

.checkout-bill {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #d5d5d5;
    font-family: "Koho", sans-serif;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

@media only screen and (max-width: 992px) {
    .checkout-bill {
        margin-bottom: 24px;
    }
}

.checkout-bill-head {
    text-align: center;
    font-size: 24px;
    color: #2d4271;
    border-bottom: 1px solid #e7e7e7;
}

.checkout-bill-info {
    margin: 20px 0;
}

.checkout-bill-info__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

    .checkout-bill-info__item p {
        margin-bottom: 0;
    }

    .checkout-bill-info__item:not(:last-child) {
        margin-bottom: 16px;
    }

    .checkout-bill-info__item > span {
        color: #2d4271;
        font-weight: 600;
    }

.checkout-bill-info__name {
    font-weight: 700;
    font-size: 20px;
    color: #2d4271;
}

.checkout-bill-info__voucher {
    padding-bottom: 8px;
    border-bottom: 1px solid #e7e7e7;
}

@media only screen and (max-width: 375px) {
    .checkout-bill-info__voucher {
        display: flex;
        flex-direction: column;
    }

        .checkout-bill-info__voucher > div {
            width: 100%;
        }
}

.checkout-bill-info__voucher input {
    padding: 8px 12px;
    font-size: 12px;
    border-radius: 4px;
}

    .checkout-bill-info__voucher input[type="text"] {
        border: 1px solid #e7e7e7;
        width: 80%;
        margin: 0 4px;
    }

    .checkout-bill-info__voucher input[type="button"] {
        width: 30%;
        margin-left: 2px;
        border: transparent;
    }

.checkout-bill-info__voucher .checkout-btn-get {
    background-color: #198754;
}

.checkout-bill-foot__price {
    color: #00b6f3;
}

.checkout-bill-foot__discount {
    color: #48bb78;
}

.checkout-bill-foot__total {
    color: #fd5056;
}

.checkout-bill-foot p {
    font-size: 16px;
    font-weight: 600;
}

@media only screen and (max-width: 375px) {
    .checkout-bill-foot p {
        font-size: 14px;
    }
}

.checkout-bill-foot__price, .checkout-bill-foot__discount, .checkout-bill-foot__total {
    font-size: 20px;
    font-weight: bold;
}

@media only screen and (max-width: 375px) {
    .checkout-bill-foot__price, .checkout-bill-foot__discount, .checkout-bill-foot__total {
        font-size: 16px;
    }

    .checkout-bill-info__name {
        font-size: 1.5rem;
    }

    .checkout-bill-head {
        font-size: 1.6rem;
    }
}

@media only screen and (max-width: 320px) {
    .modal-lucky.modal-lucky__payment {
        min-width: 95%;
    }
}
