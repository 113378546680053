/* PUBLIC */
.hide {
    display: none;
}

#showTourTicket {
    margin-top: 2rem;
}

.filter-header {
    display: none;
}

.set-col__form {
    padding-left: 20px;
    padding-right: 20px;
}

.set-col__container {
    padding-left: 10px;
    padding-right: 10px;
}

.set-row__margin {
    margin-left: -10px;
    margin-right: -10px;
}

.set-col__padding {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.btn {
    padding: 6px 12px;
    font-size: 14px;
}

.collapse-filter {
    cursor: pointer;
    transition: all .7s ease-in-out;
}
/* PRIVATE */
.sliders {
    position: relative;
    background-image: url(../../../wwwroot/images/booknet.png) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    min-height: 59rem;
    height: 100%;
}

    .container-build__form .build-form, .sliders .build-form {
        min-width: 320px;
        background: linear-gradient(11.3deg, #D91A21 30.49%, #6F0000 186.99%);
        color: #fff;
        border-radius: 0.7rem;
        opacity: 0.9;
        width: 32rem;
        margin-top: 10rem;
    }

        .sliders .build-form .build-heading h4 {
            margin: 0;
            padding: .7rem;
            font-size: 1.6rem;
        }

    .sliders .build-content {
        padding: .7rem;
    }

        .sliders .build-content .panel-build__tour {
            margin-bottom: .7rem;
        }

.build-content .panel-build__tour.build-toud__col {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -.3rem;
}

    .build-content .panel-build__tour.build-toud__col .build-item {
        width: calc(100%/2 - .3rem);
        margin-left: .3rem;
    }

        .build-content .panel-build__tour.build-toud__col .build-item input {
            position: relative;
            /*padding: 14px 2px 14px 6px;*/
            user-select: none;
        }

    .build-content .panel-build__tour.build-toud__col .form-control.date-start,
    .build-content .panel-build__tour.build-toud__col .form-control.date-end {
        font-size: 12px;
    }

@media only screen and (max-width: 325px) {
    .build-content .panel-build__tour.build-toud__col .form-control.date-start,
    .build-content .panel-build__tour.build-toud__col .form-control.date-end {
        font-size: 1rem;
    }
}


.build-content .panel-build__tour.build-toud__col .build-item .date-start::-webkit-calendar-picker-indicator,
.build-content .panel-build__tour.build-toud__col .build-item .date-end::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto;
    height: auto;
    background: transparent;
    cursor: pointer;
    user-select: none;
    z-index: 2;
}

input::-webkit-datetime-edit-day-field:focus,
input::-webkit-datetime-edit-month-field:focus,
input::-webkit-datetime-edit-year-field:focus {
    background-color: transparent;
    color: #495057
}

.build-content .panel-build__tour.build-toud__col .build-item .build-item__icon {
    position: absolute;
    right: 8px;
    top: 8px;
    color: #A42122;
    z-index: 1;
}

.sliders .build-content .panel-build__tour.build-toud__col .build-space {
    margin: 0 .3rem;
}

.sliders .content-build__form {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

#showTourTicket .set-col__padding {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

#showTourTicket .filter-content .filter-heading .filter-title {
    font-size: 20px;
    font-weight: 600;
}

.ticket-content .ticket-inner {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.ticket-content {
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    transition: box-shadow .2s ease-in-out;
    margin-bottom: 2rem;
    height: 55rem;
    cursor: pointer;
}

    .ticket-content:hover {
        box-shadow: 0 0 11px rgba(33,33,33,.2);
    }

    .ticket-content .ticket-inner .ticket-sub {
        padding: 0 1rem;
    }

        .ticket-content .ticket-inner .ticket-sub .ticket-des {
            color: #848d92;
            font-weight: 400;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            height: 100px;
        }

        .ticket-content .ticket-inner .ticket-sub .ticket-heading {
            font-size: 1.8rem;
            margin-top: 0;
        }

            .ticket-content .ticket-inner .ticket-sub .ticket-heading p {
                text-decoration: none;
                color: #214252;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                transition: all .1s ease-in-out;
                cursor: pointer;
            }

                .ticket-content .ticket-inner .ticket-sub .ticket-heading p:hover {
                    color: #fd5056 !important;
                }

        .ticket-content .ticket-inner .ticket-sub .ticket-footer {
            display: flex;
            flex-direction: column;
            margin-bottom: 1rem;
            color: #2a323f;
        }

    .ticket-content .ticket-inner .ticket-image {
        width: 100%;
        flex-shrink: 0;
        height: 250px;
        border-radius: 20px;
        overflow: hidden;
        padding: 1rem;
    }

        .ticket-content .ticket-inner .ticket-image span {
            width: 100%;
            height: 100%;
        }

        .ticket-content .ticket-inner .ticket-image img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
            border-radius: 1rem;
            transition: all 0.3s;
        }

    .ticket-content .ticket-inner .ticket-sub .ticket-heading .title-price.title-show,
    .ticket-content .ticket-inner .ticket-price {
        color: #FBA504;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

        .ticket-content .ticket-inner .ticket-price .title-price {
            font-size: 1.6rem;
            font-weight: 600;
            display: flex;
            align-items: center;
            margin: 0;
        }

        .ticket-content .ticket-inner .ticket-sub .ticket-heading .title-price.title-show span,
        .ticket-content .ticket-inner .ticket-price span {
            display: flex;
            color: #2a323f;
            font-size: 15px;
            font-weight: normal;
            justify-content: flex-end;
        }

    .ticket-content .ticket-inner .ticket-sub .ticket-heading .title-price.title-show {
        display: none;
    }

.ticket-price a.button-detail {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .1rem;
    text-transform: uppercase;
    border-color: #214252;
    background-color: #214252;
    color: #fff;
    border: 1px solid;
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.ticket-price .button-detail:hover {
    background-color: transparent;
    color: #214252;
    border-color: #214252;
    text-align: center;
}

input[type="checkbox"],
input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 1.5rem;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid #4f565d;
    background: #fff;
    transition: background 0.3s, border-color 0.3s, outline 0.2s;
}

    input[type="checkbox"]:after,
    input[type="radio"]:after {
        content: "";
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform 0.3s ease, opacity 0.2s;
    }

    input[type="checkbox"]:checked,
    input[type="radio"]:checked {
        background-color: #275efe;
        border-color: #275efe;
        transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.32, 1.2), opacity 0.3s;
    }

    input[type="checkbox"]:disabled,
    input[type="radio"]:disabled {
        background-color: #f6f8ff;
        border-color: #8f8f8f;
        cursor: not-allowed;
    }

        input[type="checkbox"]:disabled:checked,
        input[type="radio"]:disabled:checked {
            background-color: #8f8f8f;
        }

        input[type="checkbox"]:disabled + label,
        input[type="radio"]:disabled + label {
            cursor: not-allowed;
        }

    input[type="checkbox"]:hover:not(:checked):not(:disabled),
    input[type="radio"]:hover:not(:checked):not(:disabled) {
        border-color: #275efe;
    }

    input[type="checkbox"]:focus-visible,
    input[type="radio"]:focus-visible {
        outline: 2px solid #275efe;
        outline-offset: 4px;
    }

    input[type="checkbox"]:not(.switch),
    input[type="radio"]:not(.switch) {
        width: 1.5rem;
        margin-right: 1rem;
    }

    input[type="checkbox"] + label,
    input[type="radio"] + label {
        display: inline-block;
        cursor: pointer;
        font-size: 1.2rem;
        margin-left: 0.2em;
        margin-top: -0.1rem;
        vertical-align: top;
        font-weight: 700;
    }

    input[type="checkbox"]:not(.switch),
    input[type="radio"]:not(.switch) {
        border-radius: 0.3rem;
    }

        input[type="checkbox"]:not(.switch):after,
        input[type="radio"]:not(.switch):after {
            border: 2px solid #fff;
            height: 80%;
            width: 40%;
            border-top: 0;
            border-left: 0;
            left: 33%;
            top: 7%;
            transform: rotate(20deg);
        }

        input[type="checkbox"]:not(.switch):disabled:not(:checked):after,
        input[type="radio"]:not(.switch):disabled:not(:checked):after {
            border-color: #f6f8ff;
        }

        input[type="checkbox"]:not(.switch):checked:after,
        input[type="radio"]:not(.switch):checked:after {
            transform: rotate(43deg);
        }

    input[type="checkbox"].switch,
    input[type="radio"].switch {
        width: 3.8rem;
        border-radius: 1.1rem;
    }

        input[type="checkbox"].switch:after,
        input[type="radio"].switch:after {
            left: 5%;
            top: 10%;
            border-radius: 50%;
            width: 45%;
            height: 80%;
            background: #4f565d;
            transform: translateX(0);
        }

        input[type="checkbox"].switch:checked:after,
        input[type="radio"].switch:checked:after {
            background: #f6f8ff;
            transform: translateX(100%);
        }

        input[type="checkbox"].switch:disabled:not(:checked):after,
        input[type="radio"].switch:disabled:not(:checked):after {
            background-color: #8f8f8f;
        }

input[type="radio"] {
    border-radius: 25%;
}

/*    input[type="radio"]:after {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: #fff;
        transform: scale(0.7);
    }

    input[type="radio"]:disabled:after {
        background: #f6f8ff;
    }

    input[type="radio"]:checked:after {
        transform: scale(0.5);
    }*/


input[type=file]:focus, input[type=radio]:focus, input[type=checkbox]:focus {
    outline: 0;
}

ul.fields {
    margin: 1.2rem;
    padding: 0;
    list-style: none;
    width: 100%;
    max-width: 32rem;
}

    ul.fields li {
        margin: 1rem 0;
        position: relative;
    }

.filter-content {
    border: 1px solid #ccc;
    padding: 1rem;
    border-radius: 0.5rem;
    /*margin-top: 52px;*/
}

    .filter-content .filter-heading {
        margin-bottom: 2rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #f5f5f5;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
    }

        .filter-content .filter-heading .clear-filter {
            cursor: pointer;
        }

        .filter-content .filter-heading .filter-title {
            font-size: 20px;
            font-weight: 600;
        }

    .filter-content .filter-inner .filter-block {
        border-bottom: 1px solid #f5f5f5;
    }

.filter-inner .filter-block .filter-sub {
    font-size: 1.8rem;
    font-weight: 600;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

    .filter-inner .filter-block .filter-sub svg {
        cursor: pointer;
    }

    .filter-inner .filter-block .filter-sub li {
        display: flex;
        align-items: center;
    }

.btn.button-detail {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .1rem;
    text-transform: uppercase;
    border-color: #214252;
    background-color: #214252;
    color: #fff;
    border: 1px solid;
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.ticket-price .btn.button-detail:hover {
    background-color: transparent;
    color: #214252;
    border-color: #214252;
    text-align: center;
}

#BTsearchbk, #BTsearchbkMobie {
    color: #000000;
    background: linear-gradient(26.73deg, #F9A51A 13.7%, #FBB612 29.8%, #FFDD00 66.81%);
    width: 30%;
    font-weight: 700;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-weight: 700;
}

.build-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.container-build__form {
    display: none;
}

    .container-build__form .build-heading,
    .sliders .build-heading {
        border-top-left-radius: .7rem;
        border-top-right-radius: .7rem;
        padding: .2rem .7rem;
        border-bottom: 1px dashed #a14646;
    }

    .container-build__form .build-form,
    .sliders .build-form {
        min-width: 320px;
        background: linear-gradient(11.3deg, #D91A21 30.49%, #6F0000 186.99%);
        color: #fff;
        border-radius: 0.7rem;
        opacity: 0.9;
        width: 32rem;
    }

        .container-build__form .build-form .build-heading h4,
        .sliders .build-form .build-heading h4 {
            margin: 0;
            padding: .7rem;
        }

    .container-build__form .build-content,
    .sliders .build-content {
        padding: .7rem;
    }

        .container-build__form .build-content .panel-build__tour,
        .sliders .build-content .panel-build__tour {
            margin-bottom: .7rem;
        }

            .container-build__form .build-content .panel-build__tour.build-toud__col,
            .sliders .build-content .panel-build__tour.build-toud__col {
                display: flex;
                align-items: center;
                justify-content: center;
            }

                .container-build__form .build-content .panel-build__tour.build-toud__col .buld-item,
                .sliders .build-content .panel-build__tour.build-toud__col .buld-item {
                    width: 100%;
                }

.build-space {
    margin: 0 .3rem;
}

.filter-modal {
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    border-color: #214252;
    background-color: #214252;
    color: #fff;
    border: 1px solid;
    text-decoration: none;
    cursor: pointer;
    padding: 10px 15px;
    display: inline-block;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    font-size: 12px;
}
.react-datepicker__input-time-container {
    margin: 5px 0 10px 5px;
    font-size: 12px;
}
.react-datepicker-wrapper{
    width:100%;
}
@media only screen and (max-width: 1860.98px) {
    .sliders {
        min-height: 55rem;
        height: 100%;
    }
}

@media only screen and (max-width: 1780.98px) {
    .sliders {
        min-height: 49rem;
    }
}

@media only screen and (max-width:1660.98px) {
    .container-build__form .build-form, .sliders .build-form {
        margin-top: 7rem;
    }
}

@media only screen and (max-width: 1560.98px) {
    .sliders {
        min-height: 47rem;
    }
}

@media only screen and (max-width: 1500.98px) {
    .sliders {
        min-height: 45rem;
    }
}

@media only screen and (max-width: 1441.98px) {
    .sliders {
        min-height: 44rem;
    }

        .sliders .build-form {
            margin-top: 4rem;
        }
}

@media only screen and (max-width: 1439.98px) {
    .ticket-content .ticket-inner .ticket-price .title-price {
        font-size: 1.8rem;
    }

    .ticket-price a.button-detail {
        font-size: 11px;
    }
}

@media only screen and (max-width: 1366.98px) {
    .sliders {
        min-height: 42rem;
    }

        .sliders .build-form {
            margin-top: 3.7rem;
        }

    .btn-scroll__top {
        bottom: -3rem;
    }

    .call-button {
        bottom: -2rem;
    }

    .ws-contact {
        bottom: 12.2rem;
    }
}

@media only screen and (max-width: 1300.98px) {
    .sliders {
        min-height: 39rem;
    }

        .sliders .build-form {
            margin-top: 2rem;
        }
}

@media only screen and (max-width: 1230.98px) {
    .sliders {
        min-height: 38rem;
    }
}

@media only screen and (max-width: 1199.98px) {
    .sliders {
        position: relative;
        background-image: url(../../../wwwroot/images/banner-ip.png) !important;
        min-height: 98rem;
    }

        .sliders .build-form {
            margin-top: 33rem;
        }
}

@media only screen and (max-width:1080.98px) {
    .sliders {
        min-height: 92rem;
    }
}

@media only screen and (max-width:991.98px) {
    .filter-header {
        display: block;
        margin: 1rem 0;
    }

    .filter-content {
        display: none;
    }

    .set-col__form.container-build__form .content-build__form .build-form {
        /*margin-top: unset;*/
        margin: 2rem 0;
    }

    .set-col__form.container-build__form {
        padding-left: 5px;
        padding-right: 5px;
    }

    .sliders {
        /*position: relative;
            background-image: url(../../../wwwroot/images/banner-ip.png) !important;*/
        min-height: 83rem;
    }

        .sliders .build-form {
            margin-top: 25rem;
        }

    .ticket-content .ticket-inner .ticket-image {
        width: 100%;
        height: 280px px !important;
    }

    .ticket-content {
        min-height: 57rem;
    }
}

@media only screen and (max-width:900.98px) {
    .sliders {
        min-height: 78rem;
    }
}

@media only screen and (max-width:850.98px) {
    .sliders {
        min-height: 74rem;
    }

        .sliders .build-form {
            margin-top: 18.5rem;
        }
}

@media only screen and (max-width:800.98px) {
    .sliders {
        min-height: 67rem;
    }
}

@media only screen and (max-width:767.98px) {
    .sliders {
        min-height: 59rem;
    }

    .ticket-content .ticket-inner .ticket-sub .ticket-des {
        height: unset;
    }

    .ticket-content .ticket-inner {
        display: unset;
    }

        .ticket-content .ticket-inner .ticket-price .title-price,
        .ticket-content .ticket-inner .ticket-sub .ticket-heading p {
            font-size: 1.8rem;
        }

    .ticket-content {
        min-height: unset;
        height: unset;
        margin-left: 0;
        margin-right: 0;
    }

    .sliders .hide-mobile {
        display: none;
    }

    .container-build__form {
        display: block;
        margin: 0 auto;
    }

        .container-build__form .build-form {
            width: 100%;
            min-width: unset;
        }

    .sliders .build-content .panel-build__tour.build-toud__col {
        display: block;
    }
}

@media only screen and (max-width:667.98px) {
    .sliders {
        min-height: 56rem;
    }
}

@media only screen and (max-width:600.98px) {
    .sliders {
        min-height: 51rem;
    }
}

@media only screen and (max-width:575.98px) {
    body {
        font-size: 1.4rem;
    }

    .ticket-content .ticket-inner .ticket-sub .ticket-des {
        display: none;
    }

    .sliders {
        padding-top: 15px;
        position: relative;
        background-image: url(../../../wwwroot/images/banner-mb.png) !important;
        min-height: 49rem;
    }
}

@media only screen and (max-width:520.98px) {
    .sliders {
        min-height: 44rem;
    }
}

@media only screen and (max-width:480.98px) {
    .sliders {
        min-height: 38rem;
    }
}

@media only screen and (max-width:400.98px) {
    .sliders {
        min-height: 34rem;
    }
}

@media only screen and (max-width:350.98px) {
    .sliders {
        min-height: 30rem;
    }
}

@media only screen and (max-width:320.98px) {
    .sliders {
        min-height: 24rem;
    }

    .ticket-content .ticket-inner .ticket-sub .ticket-heading .title-price.title-show span, .ticket-content .ticket-inner .ticket-price span {
        font-size: 1.4rem;
    }

    .ticket-content .ticket-inner .ticket-price .title-price, .ticket-content .ticket-inner .ticket-sub .ticket-heading p {
        font-size: 1.6rem;
    }
}
