.main {
    padding-top: 5rem;
}

.btn-scroll__top {
    position: fixed;
    bottom: -2rem;
    right: 2.4rem;
    z-index: 999;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: rgb(255, 255, 255);
    color: rgb(239, 90, 57);
    cursor: pointer;
    padding: 15px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 3px;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
}

    .btn-scroll__top.scrolled {
        transform: translateY(-5rem);
        opacity: 1;
        visibility: visible;
    }

.call-button {
    position: fixed;
    bottom: 0;
    z-index: 100;
    right: -8px;
}

    .call-button.disable {
        display: none;
    }

.button-contact {
    position: relative;
    margin-top: -10px;
    padding: 10px 10px 80px;
}

.contact.disable {
    display: none;
}

.button-contact .phone-vr {
    position: relative;
    visibility: visible;
    background-color: transparent;
    width: 83px;
    height: 90px;
    cursor: pointer;
    z-index: 11;
    backface-visibility: hidden;
    transform: translateZ(0px);
    transition: visibility 0.5s ease 0s;
    left: -5px;
    bottom: -10px;
    display: block;
}

.phone-vr-circle-fill {
    width: 60px;
    height: 60px;
    top: 12px;
    left: 12px;
    position: absolute;
    box-shadow: rgb(195, 29, 29) 0px 0px 0px 0px;
    background-color: rgba(230, 8, 8, 0.7);
    border-radius: 50%;
    border: 2px solid transparent;
    transition: all 0.5s ease 0s;
    transform-origin: 50% 50%;
    animation: 1.3s ease 0s infinite normal none running zoom;
}

.phone-vr-img-circle {
    background-color: rgb(230, 8, 8);
    width: 35px;
    height: 35px;
    line-height: 40px;
    top: 25px;
    left: 25px;
    position: absolute;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    animation: 1s ease-in-out 0s infinite normal none running phone-vr-circle-fill;
}

    .phone-vr-img-circle a {
        display: block;
        line-height: 37px;
    }

    .phone-vr-img-circle img {
        max-height: 20px;
        max-width: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

@-webkit-keyframes phone-vr-circle-fill {
    0% {
        transform: rotate(0deg) scale(1) skew(1deg);
    }

    10% {
        transform: rotate(-25deg) scale(1) skew(1deg);
    }

    20% {
        transform: rotate(25deg) scale(1) skew(1deg);
    }

    30% {
        transform: rotate(-25deg) scale(1) skew(1deg);
    }

    40% {
        transform: rotate(25deg) scale(1) skew(1deg);
    }

    50% {
        transform: rotate(0deg) scale(1) skew(1deg);
    }

    100% {
        transform: rotate(0deg) scale(1) skew(1deg);
    }
}

@-webkit-keyframes zoom {
    0% {
        transform: scale(0.9);
    }

    70% {
        transform: scale(1);
        box-shadow: transparent 0px 0px 0px 15px;
    }

    100% {
        transform: scale(0.9);
        box-shadow: transparent 0px 0px 0px 0px;
    }
}

@keyframes zoom {
    0% {
        transform: scale(0.9);
    }

    70% {
        transform: scale(1);
        box-shadow: transparent 0px 0px 0px 15px;
    }

    100% {
        transform: scale(0.9);
        box-shadow: transparent 0px 0px 0px 0px;
    }
}
/*Contact Button*/
@-webkit-keyframes shadow {
    from {
        text-shadow: 0 3px 10px rgba(255, 255, 255, 0.3);
    }

    to {
        text-shadow: 0 3px 13px rgba(255, 255, 255, 0.9);
    }
}

@keyframes shadow {
    from {
        text-shadow: 0 3px 10px rgba(255, 255, 255, 0.3);
    }

    to {
        text-shadow: 0 3px 13px rgba(255, 255, 255, 0.9);
    }
}

.ws-contact {
    position: fixed;
    bottom: 14rem;
    right: -4px;
    margin: 20px 30px 10px 0;
    z-index: 100;
    cursor: pointer;
}

    .ws-contact a:first-child img {
        width: 2rem;
    }

    .ws-contact a img {
        width: 3rem;
    }

.ws-contact-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    position: relative;
    width: 45px;
    height: 45px;
    line-height: 70px;
    background: #dc3545;
    border-radius: 50%;
    transition: 0.2s;
}

    .ws-contact-icon.btnn:before {
        position: absolute;
        content: " ";
        z-index: -1;
        top: -19px;
        left: -19px;
        background-color: #dc3545;
        width: 80px;
        height: 80px;
        border-radius: 100%;
        animation-fill-mode: both;
        -webkit-animation-fill-mode: both;
        opacity: 0.6;
        -webkit-animation: pulse 1s ease-out;
        animation: pulse 1.8s ease-out;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(0);
        opacity: 0;
    }

    25% {
        -webkit-transform: scale(0.3);
        opacity: 1;
    }

    50% {
        -webkit-transform: scale(0.6);
        opacity: .6;
    }

    75% {
        -webkit-transform: scale(0.9);
        opacity: .3;
    }

    100% {
        -webkit-transform: scale(1);
        opacity: 0;
    }
}

@keyframes pulse {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    25% {
        transform: scale(0.3);
        opacity: 1;
    }

    50% {
        transform: scale(0.6);
        opacity: .6;
    }

    75% {
        transform: scale(0.9);
        opacity: .3;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.ws-contact-icon .wh-comment {
    width: 20px;
    height: 20px;
}

.ws-contact-icon svg {
    width: 35px;
    height: 35px;
    fill: #fff;
}

.ws-contact-icon:not(:last-child).not-active {
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;
}

.ws-contact-icon:not(:last-child).is-active {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
}

.ws-contact-icon::after {
    content: attr(tooltip);
    background: #fff;
    color: #000;
    border-radius: 50px;
    padding: 10px;
    position: absolute;
    right: 150%;
    line-height: 1;
    min-width: 150px;
    display: inline-block;
    top: 8px;
    margin-right: 10px;
    font-size: 15px;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    text-wrap: nowrap;
}

.ws-contact-icon.call {
    background: #FBA504;
    transition-delay: 250ms;
}

.ws-contact-icon.mail {
    background: #08c;
    transition-delay: 200ms;
}

.ws-contact-icon.facebook {
    background: #f1f1f1;
    transition-delay: 150ms;
}

.ws-contact-icon.message {
    background: #1F7AF0;
    transition-delay: 100ms;
}

.ws-contact-icon.zalo {
    background: #018fe5;
    transition-delay: 50ms;
}

.ws-contact-icon:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

    .ws-contact-icon:hover::after {
        opacity: 1;
        visibility: visible;
        right: 100%;
    }

@media only screen and (max-width:1440px) {
    .ws-contact {
        bottom: 14.2rem;
    }
}

@media (max-width: 576px) {
    .ws-contact {
        right: -5px;
    }
}

/*Contact Button*/
.phone-bar a {
    position: absolute;
    margin-top: -65px;
    left: 30px;
    z-index: -1;
    color: rgb(255, 255, 255);
    font-size: 16px;
    padding: 7px 15px 7px 50px;
    border-radius: 100px;
    white-space: nowrap;
}

    .phone-bar a:hover {
        opacity: 0.8;
        color: rgb(255, 255, 255);
    }

@media (max-width: 736px) {
    .phone-bar {
        display: none;
    }
}
