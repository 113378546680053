/* Provide sufficient contrast against white background */
* {
    box-sizing: inherit;
    scroll-margin-top: 7rem;
}

div:where(.swal2-container) div:where(.swal2-popup) {
    font-size: 1.3rem;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: rgb(245, 245, 245);
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 6px inset;
    background-color: rgb(239, 90, 57);
}

::-webkit-scrollbar-track {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 6px inset;
    border-radius: 10px;
    background-color: rgb(245, 245, 245);
}

::selection {
    color: rgb(255, 255, 255);
    background: rgb(253, 80, 86);
    -webkit-text-fill-color: #fff;
}

html {
    font-size: 62.5%;
    line-height: 1.6rem;
    font-family: '';
    box-sizing: border-box;
}

body {
    font-family: 'KoHo', sans-serif;
    font-size: 1.4rem;
    overflow-x: hidden;
}

a {
    color: #0366d6;
}

ul, li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

code {
    color: #E01A76;
}

.btn {
    font-size: 1.4rem;
    padding: .6rem 1.2rem;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.form-control {
    font-size: 1.4rem;
}
