.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    transform-origin: center;
    transition: all .5s ease-in-out;
}

    .modal-container.enable {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
    }

.modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
}

.modal-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background: linear-gradient(64.4deg,#D81920 21.33%,#fe2214 67.61%);
    color: #fff;
    border-top-left-radius: 1rem;
}

    .modal-heading h4 {
        margin-bottom: 0;
        font-weight: 600;
    }

.modal-inner {
    min-width: 120rem;
    height: fit-content;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 1rem;
    box-shadow: 0 0 3px rgba(0,0,0,0.6);
    overflow-y: auto;
    height: 80%;
    padding-bottom: 7.5rem;
}

    .modal-inner.modal-payment__custom {
        min-width: 130rem;
    }

.modal-close {
    width: 30px;
    height: 30px;
    background-color: #fff;
    color: #333;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.modal-body {
    padding: 1rem;
}

@media only screen and (max-width:1199.98px) {
    .modal-inner {
        min-width: 95%;
        height: 55%;
    }

    .modal-inner {
        height: 60%;
    }

        .modal-inner.modal-pay {
            height: 75%;
        }
}

@media only screen and (max-width:575.98px) {
    .modal-inner {
        height: 80%;
    }
}
