/* PUBLIC */
.hide {
    display: none;
}

/* PRIVATE */
position-col-8 {
    margin-left: 7.1pt;
    margin-right: 0in
}

.panel-heading {
    padding: 15px;
    text-transform: uppercase;
    color: #535351;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 1px dotted rgba(0, 0, 0, 0.2);
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.panel-info > .panel-heading {
    background-color: #46B8DA;
    border-color: #46B8DA;
    color: #fff;
}

.panel-body {
    padding: 15px;
}

    .panel-body p {
        margin-bottom: .5rem;
    }

.panel strong {
    font-weight: bold;
    color: #000;
}

.panel .btn {
    display: inline-block;
    border-radius: 4px;
    margin-top: 16px
}
