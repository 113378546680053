.hide {
    display: none !important;
}

.custom-width {
    width: 13rem;
}

.custom-width__sl {
    width: 10rem;
}

.tour-banner {
    background-color: #fafafa !important;
    margin-top: 2rem;
}

.timeline-body-inner-detail {
    width: 70%;
    margin-right: 1rem;
}

.price-footer {
    display: flex
}

.action_btns {
    display: flex;
    align-items: center;
    justify-content: center;
}

.action_btn {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    background: #eff4f9;
    line-height: 30px;
    text-align: center;
    color: #8950fc;
    font-size: 12px;
    transition: .3s;
    display: inline-block;
    flex: 32px 0 0;
    box-shadow: 0 2px 6px 0 rgba(67,89,113,.12);
}

    .action_btn.action_btn_trash {
        color: #f65365;
    }

        .action_btn.action_btn_trash:hover {
            background-color: #f65365;
            color: #fff;
        }

.timeline-body-inner-detail .title {
    color: #214252 !important;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    font-weight: 600;
    font-size: 16px;
}

.timeline-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: all .5s ease-in-out;
    margin-bottom: 1rem;
    padding-bottom: 2rem
}

.timeline-body-inner-detail .price {
    color: #FBA504 !important;
    font-weight: 600;
    font-size: 16px;
}

.timeline-body-inner-img img {
    width: 100%;
    height: 100%;
    border-radius: 0.7rem;
    object-fit: cover;
}

.timeline-centered {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    transition: all .5s ease-in-out;
    margin-top: 4rem;
}

    .timeline-centered.fixed {
        margin-top: 2rem;
        height: unset !important;
    }

    .timeline-centered.timeline-sm .timeline-entry {
        margin-bottom: 20px !important;
        border-radius: .7rem;
    }

    .timeline-centered:before,
    .timeline-centered:after {
        content: " ";
        display: table;
    }

    .timeline-centered:after {
        clear: both;
    }

    .timeline-centered:before {
        content: '';
        position: absolute;
        display: block;
        width: 3px;
        background: #fd5056;
        left: 50%;
        top: 20px;
        bottom: 20px;
        margin-left: -4px;
    }

    .timeline-centered .timeline-entry {
        position: relative;
        width: 50%;
        float: right;
        margin-bottom: 70px;
        clear: both;
    }

        .timeline-centered .timeline-entry:before,
        .timeline-centered .timeline-entry:after {
            content: " ";
            display: table;
        }

        .timeline-centered .timeline-entry:after {
            clear: both;
        }

        .timeline-centered .timeline-entry.begin {
            margin-bottom: 0;
        }

        .timeline-centered .timeline-entry.left-aligned {
            float: left;
        }

            .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner {
                margin-left: 0;
                margin-right: -25px;
            }

                .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-time {
                    left: auto;
                    right: -215px;
                    text-align: left;
                }

                .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-icon {
                    float: right;
                }

                .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-label {
                    margin-left: 0;
                    margin-right: 85px;
                }

                    .timeline-centered .timeline-entry.left-aligned .timeline-entry-inner .timeline-label:after {
                        left: auto;
                        right: 0;
                        margin-left: 0;
                        margin-right: -9px;
                        -moz-transform: rotate(180deg);
                        -o-transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                        -ms-transform: rotate(180deg);
                        transform: rotate(180deg);
                    }

        .timeline-centered .timeline-entry .timeline-entry-inner {
            position: relative;
            margin-left: -30px;
        }

            .timeline-centered .timeline-entry .timeline-entry-inner:before,
            .timeline-centered .timeline-entry .timeline-entry-inner:after {
                content: " ";
                display: table;
            }

            .timeline-centered .timeline-entry .timeline-entry-inner:after {
                clear: both;
            }

            .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time {
                position: absolute;
                top: 8px;
                left: -245px;
                text-align: right;
                padding: 10px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
            }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time.child {
                    left: -214px;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time > span {
                    display: block;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time > span:first-child {
                        font-size: 18px;
                        font-weight: bold;
                    }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-time > span:last-child {
                        font-size: 12px;
                    }

            .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon {
                background: #fff;
                color: #999999;
                display: block;
                width: 55px;
                height: 55px;
                -webkit-background-clip: padding-box;
                -moz-background-clip: padding-box;
                background-clip: padding-box;
                border-radius: 50%;
                text-align: center;
                line-height: 57px;
                font-size: 20px;
                float: left;
            }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-primary {
                    background-color: #dc6767;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-success {
                    background-color: #5cb85c;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-info {
                    background-color: #5bc0de;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-warning {
                    background-color: #f0ad4e;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-danger {
                    background-color: #d9534f;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-red {
                    background-color: #bf4346;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-green {
                    background-color: #488c6c;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-blue {
                    background-color: #0a819c;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-yellow {
                    background-color: #f2994b;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-orange {
                    background-color: #e9662c;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-pink {
                    background-color: #bf3773;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-violet {
                    background-color: #D99ACA;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-grey {
                    background-color: #4b5d67;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-dark {
                    background-color: #594857;
                    color: #fff;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon.bg-gradient {
                    background: #EF5A39;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

            .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label {
                position: relative;
                background: #ffffff;
                margin-left: 85px;
                -webkit-background-clip: padding-box;
                -moz-background-clip: padding;
                background-clip: padding-box;
                -webkit-border-radius: 3px;
                -moz-border-radius: 3px;
                border-radius: .7rem;
                box-shadow: 2px 2px 3px rgba(0,0,0,0.3);
            }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-red {
                    background: #bf4346;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-red:after {
                        border-color: transparent #bf4346 transparent transparent;
                    }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-red .timeline-title,
                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-red p {
                        color: #ffffff;
                    }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-green {
                    background: #488c6c;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-green:after {
                        border-color: transparent #488c6c transparent transparent;
                    }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-green .timeline-title,
                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-green p {
                        color: #ffffff;
                    }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-orange {
                    background: #e9662c;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-orange:after {
                        border-color: transparent #e9662c transparent transparent;
                    }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-orange .timeline-title,
                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-orange p {
                        color: #ffffff;
                    }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-yellow {
                    background: #f2994b;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-yellow:after {
                        border-color: transparent #f2994b transparent transparent;
                    }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-yellow .timeline-title,
                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-yellow p {
                        color: #ffffff;
                    }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-blue {
                    background: #0a819c;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-blue:after {
                        border-color: transparent #0a819c transparent transparent;
                    }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-blue .timeline-title,
                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-blue p {
                        color: #ffffff;
                    }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-pink {
                    background: #bf3773;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-pink:after {
                        border-color: transparent #bf3773 transparent transparent;
                    }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-pink .timeline-title,
                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-pink p {
                        color: #ffffff;
                    }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-violet {
                    background: #9351ad;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-violet:after {
                        border-color: transparent #9351ad transparent transparent;
                    }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-violet .timeline-title,
                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-violet p {
                        color: #ffffff;
                    }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-grey {
                    background: #4b5d67;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-grey:after {
                        border-color: transparent #4b5d67 transparent transparent;
                    }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-grey .timeline-title,
                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-grey p {
                        color: #ffffff;
                    }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-dark {
                    background: #594857;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-dark:after {
                        border-color: transparent #594857 transparent transparent;
                    }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-dark .timeline-title,
                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-dark p {
                        color: #ffffff;
                    }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-danger {
                    background: #d9534f;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-danger:after {
                        border-color: transparent #d9534f transparent transparent;
                    }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-danger .timeline-title,
                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label.bg-danger p {
                        color: #ffffff;
                    }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 9px 9px 9px 0;
                    border-color: transparent #EF5A39 transparent transparent;
                    left: 0;
                    top: 20px;
                    margin-left: -9px;
                }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label .timeline-title,
                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p {
                    color: #999999;
                    margin: 0;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p + p {
                        margin-top: 5px;
                    }

                .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label .timeline-title {
                    font-family: 'Koho',sans-serif;
                    font-weight: bold;
                    padding: 1rem;
                    background: #EF5A39;
                    color: #fff !important;
                    border-top-left-radius: .7rem;
                    border-top-right-radius: .7rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 16px;
                    position: relative;
                }

                    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label .timeline-title span {
                        min-width: 25px;
                        min-height: 25px;
                        background: #00B6F3;
                        color: #fff;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 15px;
                        cursor: pointer;
                        opacity: 1 !important;
                        position: relative;
                    }

                        .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label .timeline-title span svg {
                            fill: #fff
                        }

.table#tablePayMent {
    overflow-x: hidden;
}

    .table#tablePayMent > thead > tr > th,
    .table#tablePayMent > tbody > tr > th,
    .table#tablePayMent > tfoot > tr > th,
    .table#tablePayMent > thead > tr > td,
    .table#tablePayMent > tbody > tr > td,
    .table#tablePayMent > tfoot > tr > td {
        vertical-align: middle;
    }

    .table#tablePayMent > thead > tr > th,
    .table#tablePayMent > tbody > tr > th,
    .table#tablePayMent > tfoot > tr > th {
        font-weight: 700;
    }

.table thead > tr > th,
.table tbody > tr > th,
.table tfoot > tr > th,
.table thead > tr > td,
.table tbody > tr > td,
.table tfoot > tr > td {
    padding: 8px;
}

#no-more-tables .table-success, .table-success > th,
#no-more-tables .table-success > td {
    background-color: #198754;
    color: #fff;
}

.subtitle-order {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    float: right;
    border: 1px solid #ccc;
    border-top: none;
    padding: 0.7rem;
    margin-top: -1rem;
}

.discount-footer {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.plane-end {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-90deg);
}

.plane-start {
    -webkit-transform: rotate(145deg);
    -moz-transform: rotate(90deg);
}

.schedule-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dashed #ccc;
    padding: 0.7rem 0;
    gap: 1rem;
}

.schedule-image {
    border: 1px solid #ffffff;
    position: relative;
    width: 150px;
    height: 90px;
}

    .schedule-image img {
        width: 100%;
        display: block;
        height: 100%;
        object-fit: cover;
        border-radius: 1rem;
    }

.add-schedule .schedule-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

    .add-schedule .schedule-item strong {
        font-weight: unset;
        color: #000;
    }

    .add-schedule .schedule-item input {
        margin-left: 1rem;
    }

.add-schedule {
    position: absolute;
    min-width: 58rem;
    max-height: 35rem;
    background: #fff;
    padding: 0.7rem;
    border-radius: 0.7rem;
    z-index: 1;
    top: 35px;
    right: -2px;
    opacity: 0;
    visibility: hidden;
    transform: scale(0);
    transform-origin: top right;
    transition: all .3s ease-in-out;
    box-shadow: 0 1px 5px rgba(0,0,0,.3);
    cursor: default;
    overflow-y: auto;
}

    .add-schedule label {
        width: 100%;
    }

    .add-schedule.enable {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
    }

    .add-schedule .schedule-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

        .add-schedule .schedule-item label {
            width: 100%;
        }

        .add-schedule .schedule-item input {
            margin-left: 1rem;
            min-width: 1.5rem;
        }

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label .timeline-title span:hover {
    opacity: 1 !important;
    -webkit-opacity: 1 !important;
}

.timeline-entry .timeline-entry-inner .timeline-label .timeline-body {
    padding: 1rem;
    position: relative;
}

.timeline-body-inner-img {
    width: 20rem;
    height: 15rem;
    margin-right: 1rem;
}

    .timeline-body-inner-img img {
        width: 100%;
        height: 100%;
        border-radius: 0.7rem;
        object-fit: cover;
    }

.timeline-body-inner-detail {
    width: 70%;
    margin-right: 1rem;
}

    .timeline-body-inner-detail .title {
        color: #214252 !important;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        font-weight: 600;
        font-size: 16px;
    }

    .timeline-body-inner-detail .description {
        color: #848d92 !important;
        font-weight: 400;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        height: 110px;
    }

.price-footer {
    display: flex;
}

.timeline-body-inner-detail .price {
    color: #FBA504 !important;
    font-weight: 600;
    font-size: 16px;
}

.timeline-entry .timeline-entry-inner .timeline-label .timeline-body .timeline-inner .clearItem {
    min-width: 20px;
    min-height: 20px;
    background: #D84E5F;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1.3rem;
}

.footer-tour {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-end;
}

.timeline-entry .timeline-entry-inner .timeline-label .timeline-body .timeline-inner {
    padding: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dashed #ccc;
}

    .timeline-entry .timeline-entry-inner .timeline-label .timeline-body .timeline-inner #clearItem {
        width: 20px;
        height: 20px;
        background: #D84E5F;
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        cursor: pointer;
        position: absolute;
        top: 1rem;
        right: 1.3rem;
    }

    .timeline-entry .timeline-entry-inner .timeline-label .timeline-body .timeline-inner span.disable {
        pointer-events: none;
    }

.timeline-entry .timeline-entry-inner .timeline-label .timeline-body p span:hover {
    opacity: 0.8;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label .timeline-title span {
    -webkit-opacity: .6;
    -moz-opacity: .6;
    opacity: .6;
    -ms-filter: alpha(opacity=60);
    filter: alpha(opacity=60);
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p .timeline-img {
    margin: 5px 10px 0 0;
}

    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p .timeline-img.pull-right {
        margin: 5px 0 0 10px;
    }

.tz-gallery__checkout {
    margin-bottom: 1rem;
    width: 100%;
}

.photos-grid-container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 1rem;
    align-items: start;
}

    .photos-grid-container .main-photo {
        grid-row: 1;
        grid-column: 1;
        height: 544px !important;
    }

    .photos-grid-container img {
        width: 100%;
        display: block;
        height: 100%;
        object-fit: cover;
        border-radius: 1rem;
    }

    .photos-grid-container .sub {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 1rem;
    }

    .photos-grid-container .img-box {
        border: 1px solid #ffffff;
        position: relative;
        height: 267px;
    }

    .photos-grid-container img {
        width: 100%;
        display: block;
        height: 100%;
        object-fit: cover;
        border-radius: 1rem;
    }

@media only screen and (max-width:1199.98px) {
    .photos-grid-container .img-box {
        height: 22rem;
    }

    .photos-grid-container .main-photo {
        height: 45rem !important;
    }

    .timeline-centered .timeline-entry {
        width: 95%;
    }

    .timeline-centered:before {
        left: 5%;
    }
}

@media only screen and (max-width:991.98px) {
    .timeline-inner__modal {
        height: 60%;
    }

    .timeline-body-inner-detail {
        width: 65%;
    }
}

@media only screen and (max-width: 800px) {

    /* Force table to not be like tables anymore */
    #no-more-tables table,
    #no-more-tables thead,
    #no-more-tables tbody,
    #no-more-tables th,
    #no-more-tables td,
    #no-more-tables tr {
        display: block;
    }

        /* Hide table headers (but not display: none;, for accessibility) */
        #no-more-tables thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

    #no-more-tables tr {
        border: 1px solid #ccc;
    }

    #no-more-tables td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
        white-space: normal;
        /*text-align: left;*/
    }

    #no-more-tables .table-total__price {
        padding-left: unset;
    }

        #no-more-tables .table-total__price.table-total__title {
            color: #03000e !important;
        }

    #no-more-tables td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
        font-weight: bold;
        color: #03000e;
    }

    /*
	Label the data
	*/
    #no-more-tables td:before {
        content: attr(data-title);
    }

    .subtitle-order {
        align-items: center;
    }
}

@media only screen and (max-width:767.98px) {
    .photos-grid-container .img-box {
        height: 17rem;
    }

    .photos-grid-container .main-photo {
        height: 35rem !important;
    }

    .subtitle-order {
        width: 100%;
    }
}

@media only screen and (max-width:575.98px) {
    .photos-grid-container {
        grid-template-columns: 1fr;
    }

        .photos-grid-container .sub {
            display: none;
        }

    .timeline-centered .timeline-entry .timeline-entry-inner {
        margin-left: -17px;
    }

        .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon {
            width: 35px;
            height: 35px;
            font-size: 16px;
        }

        .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label {
            margin-left: 48px;
        }

    .timeline-centered:before {
        left: 6%;
    }

    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label .timeline-title span {
        min-width: 23px;
        min-height: 23px;
        flex-shrink: 1;
        font-size: 14px;
    }

    .add-schedule {
        top: 45px;
        min-width: 32rem;
        right: -9px;
    }

    .timeline-heading h4 {
        font-size: 1.4rem;
    }

    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label .timeline-title {
        font-size: 13px;
        padding: .5rem
    }

    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label:after {
        border-width: 7px 7px 7px 0;
        margin-left: -7px;
        top: 12px;
    }

    .add-schedule .schedule-item {
        font-size: 12px;
    }

    .timeline-centered::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    .timeline-centered::-webkit-scrollbar {
        width: 3px;
        background-color: #F5F5F5;
    }

    .timeline-centered::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #EF5A39;
    }

    .timeline-entry .timeline-entry-inner .timeline-label .timeline-body {
        font-size: 12px;
    }

        .timeline-entry .timeline-entry-inner .timeline-label .timeline-body .timeline-inner {
            flex-direction: column;
            margin-top: 2rem;
        }

    .timeline-body-inner-img {
        margin-right: 0;
    }

    .timeline-body-inner-detail .description {
        display: none;
    }

    .timeline-body-inner-detail .title {
        text-align: center;
    }

    .price-footer {
        justify-content: center;
    }

    .timeline-body-inner-img {
        width: 100%;
        margin-bottom: 0.7rem;
    }

    .timeline-body-inner-detail {
        width: 100%;
        margin-right: 0;
        margin-bottom: .7rem;
    }

    .timeline-entry .timeline-entry-inner .timeline-label .timeline-body .timeline-inner #clearItem {
        right: 0.7rem;
    }
}

@media only screen and (min-width:1280px) {
    .table-total__price {
        width: 15rem;
    }
}

@media only screen and (max-width:375.98px) {
    .add-schedule {
        min-width: 32rem;
    }

    .timeline-centered .timeline-entry .timeline-entry-inner {
        margin-left: -15px;
    }

    .timeline-centered:before {
        left: 6.8%;
    }

    .timeline-entry .timeline-entry-inner .timeline-label .timeline-body .timeline-inner p {
        font-size: 13px;
    }
}

@media only screen and (max-width:360.98px) {
    .timeline-centered:before {
        left: 9%;
    }

    .timeline-centered .timeline-entry .timeline-entry-inner {
        margin-left: -11px;
    }

        .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label .timeline-title span i {
            font-size: 9px;
        }

        .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label .timeline-title {
            font-size: 10px;
        }

    .add-schedule {
        min-width: 23rem;
    }

    .photos-grid-container .main-photo {
        height: 269px !important;
    }

    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label .timeline-title span {
        min-width: 20px;
        min-height: 20px;
    }

    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label {
        margin-left: 44px;
    }

        .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label:after {
            top: 7px;
        }

    .introjs-tooltip {
        min-width: 22rem;
    }

    .timeline-entry .timeline-entry-inner .timeline-label .timeline-body .timeline-inner p {
        font-size: 12px;
    }

    .schedule-content {
        flex-direction: column;
    }

    .schedule-image {
        width: 100%;
        height: 100px;
    }

    #no-more-tables td:before {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: normal;
        text-align: left;
        font-weight: bold;
    }
}

@media only screen and (max-width: 280.98px) {
    .add-schedule {
        min-width: 20rem;
    }

    .schedule-image {
        width: 100%;
        height: 124px;
    }

    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon {
        width: 25px;
        height: 25px;
        font-size: 11px;
    }

    .timeline-centered:before {
        left: 7%;
    }

    .timeline-centered .timeline-entry .timeline-entry-inner .timeline-label {
        margin-left: 33px;
    }
}


.popup-slide.modal-inner {
    padding: 0;
}

@media only screen and (max-width: 1200px) {
    .popup-slide.modal-inner {
        height: 80%;
    }
}

@media only screen and (max-width: 768px) {
    .popup-slide.modal-inner {
        height: 60%;
    }
}

.popup-slide.modal-inner .modal-body {
    width: 100%;
    height: 100%;
}

.popup-slide .popup {
    width: 100%;
    height: 100%;
}

    .popup-slide .popup.show {
        display: block;
    }

    .popup-slide .popup .popup-close {
        width: 30px;
        height: 30px;
        background-color: #fff;
        color: #333;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all .3s ease-in-out;
        position: absolute;
        z-index: 10;
        top: 1rem;
        right: 1rem;
    }

.popup-slide.modal-inner .popup .popup-inner {
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 1rem;
    box-shadow: 0 0 3px rgba(0,0,0,0.6);
    height: 100%;
    width: 100%;
}

.popup-slide .popup .popup-body {
    position: inherit;
    width: 100%;
    height: 100%;
    color: #ffffff;
    background-color: #1e1e22;
    border-radius: 1rem;
    overflow: hidden;
}

.popup-slide .popup-body .swiper {
    height: 100%;
}

.popup-slide .swiper .swiper-slide .swiper-slide__image {
    width: 100%;
    height: 100%;
}

    .popup-slide .swiper .swiper-slide .swiper-slide__image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 1rem;
    }

.popup-slide .swiper .swiper-slide .swiper-slide__title {
    position: absolute;
    bottom: 130px;
    width: 82%;
    left: 50px;
}

@media only screen and (max-width: 768px) {
    .popup-slide .swiper .swiper-slide .swiper-slide__title {
        bottom: 80px;
    }
}

@media only screen and (max-width: 480px) {
    .popup-slide .swiper .swiper-slide .swiper-slide__title {
        left: 24px;
    }
}

.popup-slide .popup-body .swiper-slide .swiper-slide__title {
    text-transform: uppercase;
    font-family: "Times New Roman";
    font-weight: 900;
    font-size: 4rem;
}

@media only screen and (max-width: 768px) {
    .popup-slide .popup-body .swiper-slide .swiper-slide__title {
        font-size: 3rem;
    }
}

@media only screen and (max-width: 480px) {
    .popup-slide .popup-body .swiper-slide .swiper-slide__title {
        font-size: 2rem;
    }
}

@media only screen and (max-width: 320px) {
    .popup-slide .popup-body .swiper-slide .swiper-slide__title {
        font-size: 1.5rem;
    }
}

.popup-slide .swiper-button-prev, .popup-slide .swiper-button-next {
    position: relative;
    top: unset;
    bottom: 92px;
    left: 50px;
    padding: 24px 28px;
    border: 1px solid #fff;
    user-select: none;
    cursor: pointer;
    z-index: 1;
}

.popup-slide .swiper-button-next {
    bottom: 120px;
    right: unset;
    left: 110px;
}

    .popup-slide .swiper-button-prev:before, .popup-slide .swiper-button-next:before {
        position: absolute;
        content: '';
        bottom: 0;
        right: 0;
        width: 0%;
        height: 100%;
        background-color: #FFFFFF;
        transition: all .3s;
        z-index: -1;
    }

    .popup-slide .swiper-button-next:before {
        left: 0;
    }

    .popup-slide .swiper-button-prev:hover,
    .popup-slide .swiper-button-next:hover {
        color: #fff;
    }

        .popup-slide .swiper-button-prev:hover:before,
        .popup-slide .swiper-button-next:hover:before {
            width: 100%;
        }

        .popup-slide .swiper-button-prev:hover:after,
        .popup-slide .swiper-button-next:hover:after {
            color: #000;
        }

@media only screen and (max-width: 768px) {
    .popup-slide .swiper-button-prev {
        bottom: 50px;
    }

    .popup-slide .swiper-button-next {
        bottom: 78px;
    }
}

@media only screen and (max-width: 480px) {
    .popup-slide .swiper-button-prev, .popup-slide .swiper-button-next {
        bottom: 46px;
        left: 24px;
        padding: 16px 24px;
    }

    .popup-slide .swiper-button-next {
        bottom: 68px;
        left: 76px;
    }
}

@media only screen and (max-width: 320px) {
    .popup-slide .swiper-button-prev, .popup-slide .swiper-button-next {
        height: unset;
        padding: 10px 18px;
    }

    .popup-slide .swiper-button-next {
        left: 64px;
        bottom: 60px;
    }
}

.popup-slide .swiper-button-prev::after, .popup-slide .swiper-button-next::after {
    font-size: 14px;
    font-weight: bold;
}

.popup-slide .swiper .swiper-slide.swiper-slide-prev .swiper-slide__image,
.popup-slide .swiper .swiper-slide.swiper-slide-next .swiper-slide__image {
    transform: scale(0.5);
    transition: all .5s ease;
}

.popup-slide .swiper .swiper-slide.swiper-slide-active .swiper-slide__image {
    transform: scale(1);
    transition: all 2s ease;
}
