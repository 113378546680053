:root {
    --color-primary: #EF5A39;
}

/* PUBLIC */
.hide {
    display: none;
}

/* PRIVATE */
.order {
    padding-top: 20px;
}

.order-title {
    display: flex;
    margin-bottom: 1rem;
    background-color: #ed4621;
    align-items: center;
    justify-content: center;
}

.order-title__heading {
    font-weight: 700;
    color: #fff;
    font-size: 18px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
    .order-title__heading {
        font-size: 16px;
        text-align:center;
    }
}

@media only screen and (max-width: 325px) {
    .order-title__heading {
        font-size: 13px;
    }
}

.photos-grid-container .sub {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 1rem;
}

.book-info {
    margin-bottom: 2rem !important;
    margin: 0 auto;
    position: relative;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: linear-gradient(64.4deg,#D81920 21.33%,#fe2214 67.61%);
    border-radius: .7rem;
}

    .book-info center {
        padding: .7rem;
    }

        .book-info center strong {
            color: #fff;
            font-family: 'KoHo', sans-serif;
            font-size: 18px;
        }

    .book-info.service .form-info {
        background: #fff;
        border-bottom: 3px solid #fe2214;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .book-info .group-info {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

        .book-info .group-info .info-item {
            margin: .7rem;
        }

            .book-info .group-info .info-item .control-label {
                margin-bottom: .7rem !important;
                font-family: "Koho",sans-serif !important;
            }

            .book-info .group-info .info-item #BtnSearchRoom {
                color: #000000;
                width: fit-content;
                height: 40px;
                font-size: 16px;
                background: linear-gradient(26.73deg, #F9A51A 13.7%, #FBB612 29.8%, #FFDD00 66.81%);
                font-family: "KoHo",sans-serif;
                border-radius: 5px;
                box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
                padding: 6px 14px;
                min-width: 120px;
                box-sizing: border-box;
                transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                font-weight: 500;
                line-height: 1.75;
                text-transform: uppercase;
            }

.t-check-in {
    border-radius: .7rem;
}

.info-footer.service {
    background: #fff;
    padding: .7rem;
    display: flex;
    justify-content: flex-end;
    border-bottom: none;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.info-footer .info-price strong {
    text-transform: uppercase;
    font-size: 16px;
    color: #2d4271;
}

.info-footer .info-price {
    text-transform: uppercase;
    font-size: 22px;
    color: #48BB78;
    font-weight: 700;
}

.info-footer .info-price__total strong {
    text-transform: uppercase;
    font-size: 16px;
    color: #2d4271;
}

.info-footer .info-price__total {
    text-transform: uppercase;
    font-size: 22px;
    color: #fd5056;
    font-weight: 700;
}

.input-number {
    width: 80px;
    padding: 0 12px;
    vertical-align: top;
    text-align: center;
    outline: none;
}

.input-number,
.input-number-decrement,
.input-number-increment {
    border: 1px solid #ccc;
    height: 40px;
    user-select: none;
}

.input-number-decrement,
.input-number-increment {
    display: inline-block;
    width: 30px;
    line-height: 38px;
    background: #f1f1f1;
    color: #444;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}

    .input-number-decrement:active,
    .input-number-increment:active {
        background: #ddd;
    }

.input-number-decrement {
    border-right: none;
    border-radius: 4px 0 0 4px;
}

.input-number-increment {
    border-left: none;
    border-radius: 0 4px 4px 0;
}

.tour-order {
    padding: 10px;
    border: 1px solid #d5d5d5;
    border-radius: .3rem;
}

    .tour-order strong {
        font-weight: 700;
        margin-bottom: 20px;
        display: block;
    }

.item-order {
    text-align: center;
}

.tour-order .item-order img {
    border-radius: .7rem;
}

.btn-detail {
    min-width: 12rem;
}

.input-cta__date {
    width: 100%;
    height: 3.8rem;
}
    .panel-info > .panel-heading {
        background: rgb(229, 248, 254);
        color: #00B6F3;
    }

.item-order .similar-item-box img {
    width: 100%;
    height: 100%;
}

.info-label {
    width: 100%;
    font-size: 14px;
    text-align: left;
}

@media(max-width:1119.98px) {
    .input-number {
        width: 55px;
        padding: 0 5px;
    }


    .input-number-decrement, .input-number-increment {
        width: 25px;
    }
}

@media (max-width:991.98px) {
    .tz-gallery__checkout .photos-grid-container {
        grid-template-columns: 1fr;
    }

    .tz-gallery__checkout .sub {
        display: none;
    }
}

@media(max-width:767.98px) {
    .photos-grid-container .sub {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }

    .book-info .group-info {
        flex-direction: column;
        align-items: start;
    }

        .book-info .group-info .info-item:last-child label {
            display: none;
        }

    .photos-grid-container .main-photo {
        height: 267px;
    }

    .c-navigation-breadcrumbs {
        margin-top: 3rem;
    }
}

@media(max-width:375.98px) {
    .info-footer {
        flex-direction: column;
    }
}

.order-info__content {
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    border-color: #bce8f1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.order-info__content-head {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;
    background: #e5f8fe;
    color: #00b6f3;
}

    .order-info__content-head span {
        text-transform: uppercase;
        font-weight: 600;
    }

.order-info__content-head--btn {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    margin: 10px;
    padding: 6px 10px;
    border-radius: 4px;
    color: #fff;
    background-color: #00b6f3;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
}

.order-info__content-main.show {
    height: 100%;
    padding: unset;
    transition: all 0.6s ease;
    animation: show 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) both;
    overflow-y: auto;
    max-height: 68rem
}

    .order-info__content-main.show::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 6px inset;
        background-color: #00b6f3;
    }

    .order-info__content-main.show

    .order-info__content-main.hide {
        height: 0;
        padding: 0;
        transition: all 0.6s ease;
        animation: hide 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) both;
    }

ul.list {
    margin: 0;
    padding: 0;
    list-style: none;
}

    ul.list li {
        display: flex;
        padding: 15px;
        flex-direction: column;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
        margin-bottom: 10px;
        background-color: #fff;
        border-left: 4px solid #fff;
        transition: all 0.3s ease;
        position: relative;
        cursor: pointer;
        border-radius: 4px;
        overflow: hidden;
    }

        ul.list li .title {
            font-weight: bold;
            font-size: 18px;
            position: relative;
            z-index: 1;
            line-height: 24px;
            padding-bottom: 8px;
            border-bottom: 1px solid #e7e7e7;
        }

        ul.list li .subtitle {
            position: relative;
            z-index: 1;
            font-size: 1.5rem;
            font-weight: 500;
        }

            ul.list li .subtitle.subtitle-price {
                font-weight: 700;
                font-size: 1.7rem;
            }

        ul.list li:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 0%;
            height: 100%;
            transition: width 0.3s ease;
        }

        ul.list li:hover {
            color: #fff;
        }

            ul.list li:hover:after {
                width: 100%;
                transition: width 0.3s ease;
            }
        /* Work */
        ul.list li.work:after {
            background: linear-gradient(102deg, #00b6f3 0%, #38a8cd 100%);
        }

        ul.list li.work {
            border-left-color: #00b6f3;
        }

            ul.list li.work:hover .info-item__block.service .input-number,
            ul.list li.work:hover .info-item__block.service .input-number-decrement,
            ul.list li.work:hover .info-item__block.service .input-number-increment {
                color: #fff;
            }

            ul.list li.work .info-item {
                z-index: 1;
                margin-top: 1rem;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;
            }

                ul.list li.work .info-item .btn.btn-book {
                    width: 16%;
                }

.info-item__block {
    z-index: 1;
}

    .info-item__block.service {
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        border-bottom: 1px solid #e7e7e7;
    }

        .info-item__block.service .info-label {
            width: unset;
        }

        .info-item__block.service .input-number {
            border: none;
            background: transparent;
            width: 40px;
            pointer-events: none;
        }

        .info-item__block.service .input-number-decrement,
        .info-item__block.service .input-number-increment {
            background: unset;
            border: none;
            font-size: 2.4rem;
        }

@media only screen and (max-width:320.98px) {
    ul.list li.work .info-item {
        justify-content: center;
    }

    ul.list li .title {
        font-size: 1.5rem;
    }
}
