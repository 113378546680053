/* PUBLIC */
.hide {
    display: none;
}

/* PRIVATE */
.status {
    display: flex;
    justify-content: center;
    align-items: center;
    /*min-height: 50vh;*/
}

    .status .status-wrap {
        margin-top: 50px;
        text-align: center;
        width: 380px;
        padding: 24px 48px;
        border-radius: 8px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }

    .status.success .status-wrap {
        border-top: 6px solid #51BBA0;
    }

    .status.error .status-wrap {
        border-top: 6px solid #EC4E4B;
    }

@media only screen and (max-width: 375px) {
    .status .status-wrap {
        padding: 24px 28px;
    }
}

.status .status-wrap__icon #completion {
    display: block;
    margin: auto;
}

@keyframes hideshow {
    0% {
        opacity: 0.2;
    }

    10% {
        opacity: 0.2;
    }

    15% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
    }
}

.status .status-wrap__icon #cirkel {
    animation: hideshow 0.4s ease;
}

.status .status-wrap__icon #check {
    animation: hideshow 0.4s ease;
}

.status .status-wrap__icon #stars {
    animation: scalezoom 1.0s ease;
    opacity: 0.9;
}


@keyframes scalezoom {
    0% {
        transform: scale(0.2);
        transform-origin: initial;
    }

    100% {
        transform: scale(1.0);
        transform-origin: initial;
    }
}

@keyframes draaien {
    0% {
        transform: rotate(40deg);
        transform-origin: initial;
    }

    100% {
        transform: scale(0deg);
        transform-origin: initial;
    }
}

.status .status-wrap__icon #check {
    animation: draaien 0.8s ease;
}

.status .status-wrap__icon #check {
    animation: transparent 2s;
}

@keyframes transparent {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.status .status-wrap__text {
    margin: 24px 0;
}

    .status .status-wrap__text .status-wrap__text-heading {
        font-size: 24px;
        font-weight: 600;
        color: #51BBA0;
        letter-spacing: .3rem;
    }

.status.success .status-wrap__text .status-wrap__text-heading {
    color: #51BBA0;
}

.status.error .status-wrap__text .status-wrap__text-heading {
    color: #EC4E4B;
}

@media only screen and (max-width: 375px) {
    .status .status-wrap__text .status-wrap__text-heading {
        font-size: 20px;
    }
}

.status .status-wrap__text .status-wrap__text-para {
    color: #343a40;
    font-size: 13px;
}

@media only screen and (max-width: 375px) {
    .status .status-wrap__text .status-wrap__text-para {
        font-size: 12px;
    }
}

.status .status-wrap__btn a {
    position: relative;
    display: block;
    text-decoration: none;
    color: #fff;
    border-radius: 4px;
    padding: 12px 16px;
    font-weight: bold;
    background: #51BBA0;
}

.status.success .status-wrap__btn a {
    background: #51BBA0;
}

.status.error .status-wrap__btn a {
    background: #EC4E4B;
}

.status .status-wrap__btn a::before {
    position: absolute;
    content: "\27F5";
    left: 10px;
    top: 50%;
    font-size: 32px;
    font-weight: bold;
    opacity: 0;
    transform: translate(0, -50%);
    transition: all .5s ease;
}

.status .status-wrap__btn a:hover {
    opacity: .8;
}

    .status .status-wrap__btn a:hover::before {
        opacity: 1;
        transform: translate(35%, -50%);
    }

.o-circle {
    display: flex;
    width: 10.555rem;
    height: 10.555rem;
    justify-content: center;
    align-items: flex-start;
    border-radius: 50%;
    animation: circle-appearance .8s ease-in-out 1 forwards, set-overflow .1s 1.1s forwards;
}

.c-container__circle {
    margin: 5.5rem auto;
}

.o-circle__sign {
    position: relative;
    opacity: 0;
    background: #fff;
    animation-duration: .8s;
    animation-delay: .2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

    .o-circle__sign::before,
    .o-circle__sign::after {
        content: "";
        position: absolute;
        background: inherit;
    }

    .o-circle__sign::after {
        left: 100%;
        top: 0%;
        width: 500%;
        height: 95%;
        transform: translateY(4%) rotate(0deg);
        border-radius: 0;
        opacity: 0;
        animation: set-shaddow 0s 1.13s ease-in-out forwards;
        z-index: -1;
    }

.o-circle__sign--failure {
    background: rgb(236, 78, 75);
}

    .o-circle__sign--failure .o-circle__sign {
        width: 1rem;
        height: 7rem;
        transform: translateY(25%) rotate(45deg) scale(.1);
        border-radius: 50% 50% 50% 50% / 10%;
        animation-name: failure-sign-appearance;
    }

        .o-circle__sign--failure .o-circle__sign::before {
            top: 50%;
            width: 100%;
            height: 100%;
            transform: translateY(-50%) rotate(90deg);
            border-radius: inherit;
        }

        /*--shadow--*/
        .o-circle__sign--failure .o-circle__sign::after {
            background: rgba(175, 57, 55, .8);
        }

.item-new.confirm {
    display:block;
}
/*-----------------------
      @Keyframes
--------------------------*/
/*CIRCLE*/
@keyframes circle-appearance {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.5);
    }

    60% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
    }
}

/*SIGN*/
@keyframes failure-sign-appearance {
    50% {
        opacity: 1;
        transform: translateY(25%) rotate(45deg) scale(1.7);
    }

    100% {
        opacity: 1;
        transform: translateY(25%) rotate(45deg) scale(1);
    }
}

@keyframes set-shaddow {
    to {
        opacity: 1;
    }
}

@keyframes set-overflow {
    to {
        overflow: hidden;
    }
}
