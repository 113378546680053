/* PUBLIC */
.hide {
    display: none;
}

/* PRIVATE */
#dk-tab {
}

#dk-panel {
}

.btn-danger2 {
    color: #d9534f;
    background-color: #fff;
    border-color: #d43f3a;
}

    .btn-danger2:hover, .btn-danger2:focus, .btn-danger2:active, .btn-danger2.active, .open .dropdown-toggle.btn-danger2 {
        color: #fff;
        background-color: #d2322d;
        border-color: #ac2925;
    }

#dk-tab a {
    white-space: unset;
}

.dk-tab-col-4 {
    border-radius: 2px;
    width: 100%;
    margin-bottom: 5px;
    text-align: left
}

.dk-tab-col-8 {
    margin-left: 7.1pt;
    margin-right: 0in
}

.terms .terms-tab {
    padding: 0;
}

    .terms .terms-tab button {
        text-align: left;
        width: 100%;
        border: none;
        background: none;
        padding: 8px 12px;
    }

.react-tabs__tab-list {
    border-bottom: none !important;
}

.tab-content__terms {
    margin-top: 3rem;
}
